import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import {
  MODAL_PROJECT_ERROR_CLOSE,
  MODAL_PROJECT_ERROR_OPEN,
  MODAL_PROJECT_ERROR_RETRY,
} from 'src/modules/modals/actions/ModalActions';
import { ModalStackState } from 'src/modules/modals/types/ModalStackState';

export function modalProjectErrorReducer(state: ModalStackState, action: Action): ModalStackState {
  if (MODAL_PROJECT_ERROR_OPEN.is(action)) {
    const filteredState = state.filter((it) => it.type !== 'project-error');

    return [...filteredState, {
      type: 'project-error',
      data: action.data,
      status: null,
    }];
  }
  if (MODAL_PROJECT_ERROR_CLOSE.is(action)) {
    return state.filter((it) => it.type !== 'project-error');
  }

  if (MODAL_PROJECT_ERROR_RETRY.isPending(action)) {
    return state.map((it) => (
      it.type === 'project-error'
        ? { ...it, status: FUTURE.pending() }
        : it
    ));
  }
  if (MODAL_PROJECT_ERROR_RETRY.isSuccess(action)) {
    return state.map((it) => (
      it.type === 'project-error'
        ? { ...it, status: FUTURE.success(action.data) }
        : it
    ));
  }
  if (MODAL_PROJECT_ERROR_RETRY.isFailure(action)) {
    return state.map((it) => (
      it.type === 'project-error'
        ? { ...it, status: FUTURE.failure(action.data) }
        : it
    ));
  }

  return state;
}
