import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { User } from 'src/modules/users/types/User';

type Props = {
  onClose: () => void;
  onDelete: () => void;
  removing: boolean;
  selectedUsers: ReadonlyArray<User>;
};

export const DeleteUserModal = ({ onClose, onDelete, removing, selectedUsers }: Props): React.ReactElement => {
  const intl = useIntl();

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        closeModal={onClose}
        label={intl.formatMessage({ id: 'admin/user/deleteModal/header/label' })}
        title={intl.formatMessage({ id: 'admin/user/deleteModal/header/title' })}
      />

      <ModalBody>
        {intl.formatMessage(
          { id: 'admin/user/deleteModal/body/content' },
          { users: selectedUsers.map((user) => `${user.firstName} ${user.lastName}`).join(', ') },
        )}
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          autoFocus={true}
          onClick={onClose}
        >
          {intl.formatMessage({ id: 'admin/user/deleteModal/footer/cancel' })}
        </CustomButton>

        <CustomButton
          disabled={removing}
          kind="primary"
          onClick={onDelete}
        >
          {intl.formatMessage({ id: 'admin/user/deleteModal/footer/confirm' })}
        </CustomButton>
      </ModalFooter>
    </>
  );
};
