import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAction } from 'src/hooks/useAction';
import { CustomBreadcrumbItem } from 'src/modules/common/components/CustomBreadcrumbItem';
import { CustomModal } from 'src/modules/common/components/CustomModal';
import { AdminLayout } from 'src/modules/common/layouts/AdminLayout';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { USER_DELETE } from 'src/modules/users/actions/UserActions';
import { USERS_LIST_ORDER, USERS_LIST_QUERY, USERS_LIST_TARGET } from 'src/modules/users/actions/UsersActions';
import { getSortedFoundSelectableUsers } from 'src/modules/users/selectors/getSortedFoundSelectableUsers';
import { getSortedFoundUsers } from 'src/modules/users/selectors/getSortedFoundUsers';
import { getUserDeleting } from 'src/modules/users/selectors/getUserDeleting';
import { getUserEditing } from 'src/modules/users/selectors/getUserEditing';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { getUsersQuery } from 'src/modules/users/selectors/getUsersQuery';
import { getUsersSortingOrder } from 'src/modules/users/selectors/getUsersSortingOrder';
import { getUsersSortingTarget } from 'src/modules/users/selectors/getUsersSortingTarget';
import { User } from 'src/modules/users/types/User';
import { AddUserModal } from 'src/modules/users/views/AddUserModal';
import { UsersListBody } from 'src/modules/users/views/UsersListBody';

export const UsersPage = memo((): React.ReactElement => {
  const intl = useIntl();

  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);

  const userDeleting = useSelector(getUserDeleting);
  const userEditing = useSelector(getUserEditing);
  const loggedInUser = useSelector(getLoggedInUser);
  const searchQuery = useSelector(getUsersQuery);
  const sortingTarget = useSelector(getUsersSortingTarget);
  const sortingOrder = useSelector(getUsersSortingOrder);
  const allUserSnapshots = useSelector(getUsersListData);
  const sortedFoundUsers = useSelector(getSortedFoundUsers);
  const sortedFoundSelectableUsers = useSelector(getSortedFoundSelectableUsers);

  const deleteUser = useAction(USER_DELETE.request);
  const setSearchQuery = useAction(USERS_LIST_QUERY.trigger);
  const setSortingTarget = useAction(USERS_LIST_TARGET.trigger);
  const setSortingOrder = useAction(USERS_LIST_ORDER.trigger);

  const handleAddUserOpen = (): void => {
    setAddUserModalOpen(true);
  };

  const handleAddUserCancel = (): void => {
    setAddUserModalOpen(false);
  };

  const handleDeleteUserSubmit = (usersToDelete: ReadonlyArray<User>): void => deleteUser(usersToDelete, usersToDelete);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'admin/users/list/title' })}</title>
      </Helmet>

      <AdminLayout
        breadcrumbs={(
          <CustomBreadcrumbItem>
            <Link to="/projects">
              {intl.formatMessage({ id: 'admin/users/header/breadcrumbs/project' })}
            </Link>
          </CustomBreadcrumbItem>
        )}
        title={
          intl.formatMessage(
            { id: 'admin/users/list/header/title' },
            { organization: loggedInUser.organization.name },
          )
        }
      >
        <UsersListBody
          loggedInUser={loggedInUser}
          userDeleting={userDeleting}
          handleDelete={handleDeleteUserSubmit}
          handleAddUserOpen={handleAddUserOpen}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          sortingTarget={sortingTarget}
          setSortingTarget={setSortingTarget}
          sortingOrder={sortingOrder}
          setSortingOrder={setSortingOrder}
          sortedFoundUsers={sortedFoundUsers}
          sortedFoundSelectableUsers={sortedFoundSelectableUsers}
        />

        <CustomModal
          open={isAddUserModalOpen}
          shouldUnmount={true}
          isShaded={false}
          onClose={handleAddUserCancel}
          size="lg"
        >
          <AddUserModal
            allUserSnapshots={allUserSnapshots}
            onCancel={handleAddUserCancel}
          />
        </CustomModal>
      </AdminLayout>
    </>
  );
});
