import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { getProjectLazy } from 'src/modules/common/selectors/getProjectLazy';
import { getCurrentDate } from 'src/modules/config/selectors/getCurrentDate';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';

export const getReportFormData = createSelector([
  getProjectLazy,
  getCurrentDate,
  getLoggedInUser,
], (project, currentDate, loggedInUser): ReportFormData => (
  {
    ...project.data.report,
    cover: {
      ...project.data.report.cover,
      creationDate: project.data.report.cover.creationDate || dayjs(currentDate).startOf('day').toDate(),
      showOrganizationLogo: loggedInUser.organization.logo !== null && project.data.report.cover.showOrganizationLogo,
    },
    energy: project.data.report.energy,
    hvac: project.data.report.hvac,
    schema: project.data.report.schema,
  }
));
