import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CircleFilledIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10">
      <circle cx="5" cy="5" r="4.5" fill="currentColor" stroke="none"/>
    </svg>
  </IconWrapper>
));
