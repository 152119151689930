import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ArrowUpIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m17.25 10.45-.515.5L12.36 6.6v12.15h-.72V6.6l-4.375 4.35-.515-.5L12 5.25l5.25 5.2Z"
      />
    </svg>
  </IconWrapper>
));
