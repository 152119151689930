import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import {
  USER_DELETE,
} from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function userDeletingReducer(state: UsersState, action: Action): UsersState {
  if (USER_DELETE.isPending(action)) {
    const deletingUserIds = action.meta.map((deletingUser) => deletingUser.id);

    return {
      ...state,
      userSnapshots: state.userSnapshots.map((userSnapshot) => (deletingUserIds.includes(userSnapshot.user.id)
        ? { ...userSnapshot, action: 'deleting' }
        : userSnapshot
      )),
      deleting: {
        status: FUTURE.pending(),
      },
    };
  }
  if (USER_DELETE.isSuccess(action)) {
    const deletedUserIds = action.meta.map((user) => user.id);
    return {
      ...state,
      userSnapshots: state.userSnapshots.filter((userSnapshot) => !deletedUserIds.includes(userSnapshot.user.id)),
      deleting: {
        status: FUTURE.success(undefined),
      },
    };
  }
  if (USER_DELETE.isFailure(action)) {
    return {
      ...state,
      userSnapshots: state.userSnapshots.map((userSnapshot) => (userSnapshot.action === 'deleting'
        ? { ...userSnapshot, action: null }
        : userSnapshot
      )),
      deleting: {
        status: FUTURE.failure(action.data),
      },
    };
  }
  return state;
}
