import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CheckIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9.448 17.25 4.5 12.336l.52-.517 4.428 4.396 9.531-9.465.521.517L9.448 17.25Z"
      />
    </svg>
  </IconWrapper>
));
