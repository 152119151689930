import './ReportDocumentPageSchema.scss';
import { SchemaArea } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MATRIX_IDENTITY } from 'src/modules/common/constants/matrix';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { FanIcon } from 'src/modules/common/icons/FanIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { OutputTitle } from 'src/modules/output/views/OutputTitle';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageSchemaCanvas } from 'src/modules/report/views/document/ReportDocumentPageSchemaCanvas';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly area: SchemaArea;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageSchema = ({ language, area, document, formData }: Props): React.ReactElement | null => {
  const currentSchema = document.schema[area];
  if (!currentSchema) {
    return null;
  }

  const { showZoomedIn, showComments } = formData.schema[area];
  const matrix = showZoomedIn
    ? currentSchema.matrix
    : MATRIX_IDENTITY;

  return (
    <OutputDocumentPage
      id={`report-schema-${area}`}
      order={SECTION_ORDER[area]}
      section={`schema-${area}`}
      visible={formData.schema[area].enabled}
    >
      <OutputPage language={language} showPageNumber={true}>
        <div className="bp-report-document-page-schema">
          <div className="bp-report-document-page-schema__header">
            <OutputTitle>
              {AREA_TITLES[area]}
            </OutputTitle>
          </div>

          <div className="bp-report-document-page-schema__section">
            <div className="bp-report-document-page-schema__title">
              <div className="bp-report-document-page-schema__title-icon">
                {AREA_ICONS[area]}
              </div>

              <FormattedMessage id="report/document/schema/common/actual"/>
            </div>

            <div className="bp-report-document-page-schema__schema" style={{ overflow: 'hidden' }}>
              <div className="bp-report-document-page-schema__chart">
                <ReportDocumentPageSchemaCanvas
                  schema={currentSchema.schema}
                  version={currentSchema.actual}
                  matrix={matrix}
                />
              </div>
            </div>
          </div>

          {currentSchema.future && (
            <div className="bp-report-document-page-schema__section">
              <div className="bp-report-document-page-schema__title">
                <div className="bp-report-document-page-schema__title-icon">
                  {AREA_ICONS[area]}
                </div>

                <FormattedMessage
                  id="report/document/schema/common/future"
                  values={{ number: currentSchema.future.number }}
                />
              </div>

              <div className="bp-report-document-page-schema__schema" style={{ overflow: 'hidden' }}>
                <div className="bp-report-document-page-schema__chart">
                  <ReportDocumentPageSchemaCanvas
                    schema={currentSchema.schema}
                    version={currentSchema.future}
                    matrix={matrix}
                  />
                </div>
              </div>

            </div>
          )}

          {showComments && currentSchema.comment.length > 0 && (
            <>
              <div className="bp-report-document-page-schema__horizontal-divider"/>

              <div>
                <div className="bp-report-document-page-schema__comment-title">
                  <FormattedMessage id="report/document/schema/common/comment"/>
                </div>

                <div className="bp-report-document-page-schema__comment-text">
                  {currentSchema.comment}
                </div>
              </div>
            </>
          )}
        </div>
      </OutputPage>
    </OutputDocumentPage>
  );
};

const SECTION_ORDER = {
  [SchemaArea.HEATING]: 6_000,
  [SchemaArea.COOLING]: 7_000,
  [SchemaArea.VENTILATION]: 8_000,
};

const AREA_TITLES = {
  [SchemaArea.HEATING]: <FormattedMessage id="report/document/schema/header/heating"/>,
  [SchemaArea.COOLING]: <FormattedMessage id="report/document/schema/header/cooling"/>,
  [SchemaArea.VENTILATION]: <FormattedMessage id="report/document/schema/header/ventilation"/>,
};

const AREA_ICONS = {
  [SchemaArea.HEATING]: <HeatingIcon/>,
  [SchemaArea.COOLING]: <CoolingIcon/>,
  [SchemaArea.VENTILATION]: <FanIcon/>,
};
