import { DATE_TIME, UUID } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { Announcement } from 'src/modules/common/types/Announcement';

export const ANNOUNCEMENT: D.Decoder<unknown, Announcement> = D.struct({
  id: UUID,
  read: D.boolean,
  date: DATE_TIME,
  title: D.string,
  description: D.string,
});

export const ANNOUNCEMENTS: D.Decoder<unknown, ReadonlyArray<Announcement>> = D.array(ANNOUNCEMENT);
