import { SagaIterator } from 'redux-saga';
import {
  USER_ADD,
  USER_DELETE,
  USER_SYNC_PERFORM,
  USER_SYNC_RESET,
  USER_SAVE,
} from 'src/modules/users/actions/UserActions';
import { userCreateSaga } from 'src/modules/users/sagas/userCreateSaga';
import { userRemoveSaga } from 'src/modules/users/sagas/userRemoveSaga';
import { userSaveSaga } from 'src/modules/users/sagas/userSaveSaga';
import { userSyncPerformSaga } from 'src/modules/users/sagas/userSyncPerformSaga';
import { cancel, take, takeLatest } from 'typed-redux-saga';

export function* usersSaga(): SagaIterator<void> {
  yield* takeLatest(USER_ADD.isRequest, userCreateSaga);
  yield* takeLatest(USER_SAVE.isRequest, userSaveSaga);
  yield* takeLatest(USER_DELETE.isRequest, userRemoveSaga);

  while (true) {
    const task = yield* takeLatest(USER_SYNC_PERFORM.isRequest, userSyncPerformSaga);

    yield* take(USER_SYNC_RESET.is);
    yield* cancel(task);
  }
}
