import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { WHOAMI_ACTION } from 'src/modules/login/actions/WhoAmIAction';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { PROFILE_SAVE } from 'src/modules/profile/actions/ProfileActions';
import { updateProfile } from 'src/modules/profile/sagas/utils/updateProfile';
import { USER_EDIT_FORM_SCHEMA } from 'src/modules/users/constants/userEditFormSchema';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* profileSaveSaga(action: GetRequestActionType<typeof PROFILE_SAVE>): SagaIterator<void> {
  const profileForm = action.data;
  const profileValues = profileForm.values.get(profileForm.currentState);

  const formValid = yield* call(formValidate, profileForm, USER_EDIT_FORM_SCHEMA);
  if (!formValid) {
    return;
  }

  try {
    yield* put(PROFILE_SAVE.pending());
    yield* call(logDebug, 'Saving profile...');
    yield* call(formSubmitStart, profileForm);

    yield* call(updateProfile, profileValues);

    yield* call(showNotification, { type: 'done', variant: 'success', messageId: 'profile/notification/profileSaved' });

    yield* call(logDebug, 'Saving profile... done');
    yield* put(PROFILE_SAVE.success());
    yield* call(formSubmitSuccess, profileForm);

    const authUser = yield* select(getLoggedInUser);
    yield* put(
      WHOAMI_ACTION.trigger({ ...authUser, firstName: profileValues.firstName, lastName: profileValues.lastName }),
    );
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Saving profile... error', error);
    yield* put(PROFILE_SAVE.failure(NativeError.wrap(error)));

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(formSubmitFailure, profileForm, {
      '': {
        path: '',
        code: 'unknown',
        value: profileForm,

        message: String(error),
        context: {},
      },
    });
  }
}
