import './ErrorModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { ProjectTitle } from 'src/modules/common/components/ProjectTitle';
import { MODAL_PROJECT_ERROR_CLOSE, MODAL_PROJECT_ERROR_RETRY } from 'src/modules/modals/actions/ModalActions';
import { ModalWindowProjectError } from 'src/modules/modals/types/ModalWindow';

type Props = {
  readonly modal: ModalWindowProjectError;
};

export const ProjectErrorModal = ({ modal }: Props): React.ReactElement => {
  const intl = useIntl();
  const closeModal = useBoundAction(MODAL_PROJECT_ERROR_CLOSE.trigger, undefined);
  const saveProject = useBoundAction(MODAL_PROJECT_ERROR_RETRY.request, modal.data);

  const loading = modal.status?.state === 'pending';

  return (
    <>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={<FormattedMessage id="projectErrorModal/title"/>}
      />

      <ModalBody className="bp-error-modal__body">
        <h2 className="bp-error-modal__body-title">
          <FormattedMessage
            id="projectErrorModal/subtitle"
            values={{ project: <b><ProjectTitle title={modal.data.data.title}/></b> }}
          />
        </h2>

        <p className="bp-error-modal__body-description">
          <FormattedMessage id="projectErrorModal/description"/>
        </p>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          onClick={closeModal}
          disabled={loading}
        >
          <FormattedMessage id="projectErrorModal/close"/>
        </CustomButton>

        <CustomButton
          disabled={loading}
          kind="primary"
          onClick={saveProject}
          autoFocus={true}
          loading={loading}
        >
          <FormattedMessage id="projectErrorModal/retry"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};
