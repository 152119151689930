import './RequestAccessModal.scss';
import { COUNTRY_CODES, CountryCode } from '@belimo-retrofit-portal/logic';
import { CheckmarkFilled } from '@carbon/icons-react';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { INTL_LOCALE_MAP } from 'src/modules/common/constants/language';
import { CARBON_THEME } from 'src/modules/common/constants/theme';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { AutocompleteField } from 'src/modules/form/components/AutocompleteField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { REQUEST_ACCESS_FORM_SCHEMA } from 'src/modules/login/constants/schema';
import { RequestAccessFormData } from 'src/modules/login/types/RequestAccessFormData';

type Props = {
  readonly onClose: () => void;
  readonly onSubmit: (form: FormController<RequestAccessFormData, FormError>) => void;
};

export const RequestAccessModal = ({ onClose, onSubmit }: Props): React.ReactElement => {
  const intl = useIntl();

  const language = useSelector(getLanguage);

  const collator = useMemo(() => new Intl.Collator(INTL_LOCALE_MAP[language]), [language]);

  const countryCodesFilteredByLanguage = useMemo(
    () => COUNTRY_CODES.map((code) => ({
      label: intl.formatMessage({ id: `common/country/name/${code}` }),
      value: code,
    }))
      .sort((optionA, optionB) => collator.compare(optionA.label, optionB.label)),
    [intl, collator],
  );

  const requestAccessForm = useForm<RequestAccessFormData, FormError>('requestAccess', {
    reinitialize: false,
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      countryCode: 'CHE',
    },
  });
  useFormValidator(requestAccessForm, REQUEST_ACCESS_FORM_SCHEMA);

  const isSubmitting = useFormWatch(requestAccessForm, requestAccessForm.submitting.get);
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(requestAccessForm);
  }, [requestAccessForm, onSubmit]);

  const isSubmitted = useFormWatch(requestAccessForm, requestAccessForm.submitSucceded.get);
  if (isSubmitted) {
    return (
      <div className="bp-request-access-modal">
        <ModalHeader
          iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
          label={intl.formatMessage({ id: 'login/requestAccess/modal/header' })}
          buttonOnClick={onClose}
        />

        <ModalBody>
          <div className="bp-request-access-modal__success-wrapper">
            <CheckmarkFilled size={64} color={CARBON_THEME.additionalSuccess100}/>

            <p className="bp-project-list-import-selector__success-text">
              <FormattedMessage id="login/requestAccess/modal/success"/>
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <CustomButton
            kind="secondary"
            autoFocus={true}
            onClick={onClose}
          >
            <FormattedMessage id="login/requestAccess/modal/close"/>
          </CustomButton>
        </ModalFooter>
      </div>
    );
  }

  return (
    <form className="bp-request-access-modal" onSubmit={handleSubmit}>
      <ModalHeader
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        label={intl.formatMessage({ id: 'login/requestAccess/modal/header' })}
        buttonOnClick={onClose}
      />

      <ModalBody className="bp-request-access-modal__form-content">
        <div className="bp-request-access-modal__form-field">
          <TextField
            field={requestAccessForm.field.at('firstName')}
            labelText={intl.formatMessage({ id: 'login/requestAccess/modal/firstName' })}
            type="text"
            size="lg"
            autoComplete="name"
            placeholder={intl.formatMessage({ id: 'login/requestAccess/modal/firstName' })}
            renderHint={constNull}
            maxLength={40}
          />
        </div>

        <div className="bp-request-access-modal__form-field">
          <TextField
            field={requestAccessForm.field.at('lastName')}
            labelText={intl.formatMessage({ id: 'login/requestAccess/modal/lastName' })}
            type="text"
            size="lg"
            autoComplete="name"
            placeholder={intl.formatMessage({ id: 'login/requestAccess/modal/lastName' })}
            renderHint={constNull}
            maxLength={40}
          />
        </div>

        <div className="bp-request-access-modal__form-field">
          <TextField
            field={requestAccessForm.field.at('companyName')}
            labelText={intl.formatMessage({ id: 'login/requestAccess/modal/companyName' })}
            type="text"
            size="lg"
            autoComplete="organization"
            placeholder={intl.formatMessage({ id: 'login/requestAccess/modal/companyName' })}
            renderHint={constNull}
            maxLength={40}
          />
        </div>

        <div className="bp-request-access-modal__form-field">
          <TextField
            field={requestAccessForm.field.at('email')}
            labelText={intl.formatMessage({ id: 'login/requestAccess/modal/email' })}
            type="text"
            size="lg"
            autoComplete="email"
            placeholder={intl.formatMessage({ id: 'login/requestAccess/modal/email' })}
            renderHint={constNull}
            maxLength={40}
          />
        </div>

        <div className="bp-request-access-modal__form-field">
          <AutocompleteField
            size="md"
            titleText={intl.formatMessage({ id: 'basedata/building/address/country' })}
            field={requestAccessForm.field.at('countryCode')}
            options={countryCodesFilteredByLanguage}
            itemToElement={renderCountryOption}
            direction="top"
            required={true}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton
          kind="secondary"
          onClick={onClose}
          disabled={isSubmitting}
        >
          <FormattedMessage id="login/requestAccess/modal/cancel"/>
        </CustomButton>

        <CustomButton
          disabled={isSubmitting}
          type="submit"
          kind="primary"
          autoFocus={true}
          loading={isSubmitting}
        >
          <FormattedMessage id="login/requestAccess/modal/submit"/>
        </CustomButton>
      </ModalFooter>
    </form>
  );
};

function renderCountryOption(option: CountryCode): React.ReactElement {
  return <FormattedMessage id={`common/country/name/${option}`}/>;
}
