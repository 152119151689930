import './UserRow.scss';
import { useForm } from '@form-ts/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { constNull } from 'fp-ts/lib/function';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAction } from 'src/hooks/useAction';
import { FormattedMessage } from 'src/migration/react-intl';
import { Checkbox } from 'src/modules/common/components/Checkbox';
import { userDateFormat } from 'src/modules/common/constants/dateFormat';
import { ChevronDownIcon } from 'src/modules/common/icons/ChevronDownIcon';
import { ChevronUpIcon } from 'src/modules/common/icons/ChevronUpIcon';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { UserRole } from 'src/modules/common/types/UserRole';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { USER_SAVE } from 'src/modules/users/actions/UserActions';
import { USER_EDIT_FORM_SCHEMA } from 'src/modules/users/constants/userEditFormSchema';
import { User } from 'src/modules/users/types/User';

type Props = {
  readonly isSelf: boolean;
  readonly currentUser: User;
  readonly isSelected: boolean;
  readonly onSelectHandler: (user: User) => void;
};

export const UserRow = memo(({
  isSelf,
  currentUser,
  isSelected,
  onSelectHandler,
}: Props) => {
  const [isRowOpened, setRowOpened] = useState(false);
  const [contentHeight, setContentHeight] = useState(40);

  const form = useForm<UserFormData, FormError>('admin.users.edit', {
    initialValues: {
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      role: currentUser.role,
    },
    reinitialize: false,
  });
  useFormValidator(form, USER_EDIT_FORM_SCHEMA);

  const saveUser = useAction(USER_SAVE.request);

  const handleSaveUser = useCallback(() => {
    const formData = form.currentState.values;

    const updatedUser = {
      ...currentUser,
      ...formData,
    };

    return saveUser(updatedUser, updatedUser);
  }, [saveUser, form, currentUser]);

  const onSelect = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    onSelectHandler(currentUser);
  }, [onSelectHandler, currentUser]);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;

    const errors = form.errors.get(form.currentState);
    const isValid = Object.entries(errors).length === 0;

    if (isValid && prevState.values !== nextState.values) {
      handleSaveUser();
    }
  }), [form, handleSaveUser]);

  return (
    <div
      className="bp-user-row"
      style={{ height: isRowOpened ? `${40 + contentHeight}px` : '40px' }}
    >
      <div
        className={clsx({ 'bp-user-row__header': true, 'bp-user-row__header--opened': isRowOpened })}
        onClick={() => setRowOpened((prev) => !prev)}
        role="row"
        tabIndex={0}
        onKeyDown={() => null}
      >
        <div className="bp-user-row__icon">
          {isRowOpened ? (<ChevronUpIcon/>) : (<ChevronDownIcon/>)}
        </div>

        <div className="bp-user-row__checkbox-wrapper">
          {!isSelf && (
            <Checkbox
              className="bp-user-row__checkbox"
              checked={isSelected}
              onChange={onSelect}
            />
          )}
        </div>

        <p className="bp-user-row__header-text">
          {currentUser.firstName}
        </p>

        <p className="bp-user-row__header-text">
          {currentUser.lastName}
        </p>

        <p className="bp-user-row__header-text">
          {currentUser.email}
        </p>

        <p className="bp-user-row__header-text">
          <FormattedMessage id={`common/role/${currentUser.role}`}/>
        </p>
      </div>

      <div
        ref={(current) => setContentHeight(current?.offsetHeight || 40)}
        className="bp-user-row__content"
      >
        <div className="bp-user-row__inputs">
          <div className="bp-user-row__content-row">
            <TextField
              field={form.field.at('email')}
              disabled={true}
              labelText={<FormattedMessage id="admin/user/form/field/username"/>}
              required={true}
              size="md"
              maxLength={50}
              theme="white"
            />

            <TextField
              field={form.field.at('firstName')}
              labelText={<FormattedMessage id="admin/user/form/field/firstName"/>}
              maxLength={50}
              renderHint={constNull}
              required={true}
              size="md"
              theme="white"
            />
          </div>

          <div className="bp-user-row__content-row">
            <TextField
              wrapperClass="bp-user-row__input-full-width"
              field={form.field.at('lastName')}
              labelText={<FormattedMessage id="admin/user/form/field/lastName"/>}
              maxLength={50}
              renderHint={constNull}
              required={true}
              size="md"
              theme="white"
            />

            <DropdownField
              wrapperClass="bp-user-row__input-full-width"
              field={form.field.at('role').transform(ensureNonNull())}
              titleText={<FormattedMessage id="admin/user/form/field/role"/>}
              disabled={isSelf}
              itemToElement={RenderItem}
              options={ROLE_LIST}
              renderSelectedItem={renderSelectedItem}
              size="md"
              theme="white"
            />
          </div>
        </div>

        <div className="bp-user-row__content-row">
          <div className="bp-user-row__detail">
            <p className="bp-user-row__text"><FormattedMessage id="admin/users/row/content/createdAt"/></p>

            <p className="bp-user-row__text">{dayjs(currentUser.createdAt).format(userDateFormat)}</p>
          </div>

          <div className="bp-user-row__detail">
            <p className="bp-user-row__text"><FormattedMessage id="admin/users/row/content/latestActivity"/></p>

            <p className="bp-user-row__text">
              {currentUser.latestActivity
                ? dayjs(currentUser.latestActivity).format(userDateFormat)
                : (
                  <FormattedMessage id="admin/users/row/content/latestActivity/empty"/>
                )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

const renderSelectedItem = (role: UserRole): React.ReactElement => (
  <span>{ROLE_NAMES[role]}</span>
);
const RenderItem = (role: UserRole): React.ReactElement => (
  <span>{ROLE_NAMES[role]}</span>
);

const ROLE_LIST = [
  UserRole.USER,
  UserRole.ADMIN,
];
const ROLE_NAMES = {
  [UserRole.USER]: <FormattedMessage id="common/role/user"/>,
  [UserRole.ADMIN]: <FormattedMessage id="common/role/admin"/>,
};
