import clsx from 'clsx';
import React, { memo, MouseEvent, ReactNode, useCallback } from 'react';
import { CustomButton } from 'src/modules/common/components/CustomButton';

type Props = {
  selected?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
};

export const OverflowMenuItem = memo(({ className, selected, onClick, children }: Props) => {
  const preventDefault = useCallback((event: React.MouseEvent): void => {
    event.preventDefault();
  }, []);

  return (
    <CustomButton
      type="button"
      kind="ghost"
      fullWidth={true}
      className={clsx(
        {
          'bp-overflow-menu-item': true,
          'bp-overflow-menu-item--selected': selected,
        },
        className,
      )}
      onClick={onClick || preventDefault}
    >
      {children}
    </CustomButton>
  );
});
