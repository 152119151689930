import './AddUserModal.scss';
import { ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import { useForm, useFormWatch } from '@form-ts/react';
import { constNull } from 'fp-ts/lib/function';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAction } from 'src/hooks/useAction';
import { CustomButton } from 'src/modules/common/components/CustomButton';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { UserRole } from 'src/modules/common/types/UserRole';
import { DropdownField } from 'src/modules/form/components/DropdownField';
import { TextField } from 'src/modules/form/components/TextField';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { ensureNonNull } from 'src/modules/form/utils/transform';
import { USER_ADD } from 'src/modules/users/actions/UserActions';
import { USER_ADD_FORM_SCHEMA } from 'src/modules/users/constants/userAddFormSchema';
import { UserSnapshot } from 'src/modules/users/types/User';

type Props = {
  readonly allUserSnapshots: ReadonlyArray<UserSnapshot>;
  readonly onCancel: () => void;
};

export const AddUserModal = ({ allUserSnapshots, onCancel }: Props): React.ReactElement => {
  const intl = useIntl();

  const addUser = useAction(USER_ADD.request);

  const form = useForm<UserFormData, FormError>('admin.users.add', {
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: UserRole.USER,
    },
    reinitialize: false,
  });

  const schema = USER_ADD_FORM_SCHEMA(
    allUserSnapshots
      .map((userSnapshot) => userSnapshot.user.email),
  );

  useFormValidator(form, schema);

  const isLoading = useFormWatch(form, form.submitting.get);
  const addUserSubmitted = useFormWatch(form, form.submitted.get);
  const addUserErrors = useFormWatch(form, form.errors.get);

  const onSubmit = (): void => addUser(form);

  useEffect(() => {
    if (addUserSubmitted && Object.entries(addUserErrors).length === 0) {
      onCancel();

      form.change(form.initialState);
    }
  }, [addUserErrors, addUserSubmitted, form, onCancel]);

  return (
    <>
      <ModalHeader
        title={intl.formatMessage({ id: 'admin/users/add/title' })}
        label={intl.formatMessage({ id: 'admin/users/add/label' })}
        iconDescription={intl.formatMessage({ id: 'modal/close/label' })}
        closeModal={onCancel}
      />

      <ModalBody>
        <div className="bp-add-user-modal">
          <div className="bp-add-user-modal__row">
            <TextField
              field={form.field.at('email')}
              labelText={intl.formatMessage({ id: 'admin/user/form/field/email' })}
              required={true}
              size="md"
              maxLength={50}
            />

            <TextField
              field={form.field.at('firstName')}
              labelText={intl.formatMessage({ id: 'admin/user/form/field/firstName' })}
              maxLength={50}
              renderHint={constNull}
              required={true}
              size="md"
            />
          </div>

          <div className="bp-add-user-modal__row">
            <TextField
              wrapperClass="bp-add-user-modal__input"
              field={form.field.at('lastName')}
              labelText={intl.formatMessage({ id: 'admin/user/form/field/lastName' })}
              maxLength={50}
              renderHint={constNull}
              required={true}
              size="md"
            />

            <DropdownField
              direction="top"
              wrapperClass="bp-add-user-modal__input"
              field={form.field.at('role').transform(ensureNonNull())}
              itemToElement={RenderItem}
              options={ROLE_LIST}
              renderSelectedItem={renderSelectedItem}
              size="md"
              titleText={intl.formatMessage({ id: 'admin/user/form/field/role' })}
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <CustomButton kind="secondary" autoFocus={true} onClick={onCancel}>
          <FormattedMessage id="admin/users/add/cancel"/>
        </CustomButton>

        <CustomButton kind="primary" onClick={onSubmit} loading={isLoading}>
          <FormattedMessage id="admin/users/add/submit"/>
        </CustomButton>
      </ModalFooter>
    </>
  );
};

const ROLE_LIST = [
  UserRole.USER,
  UserRole.ADMIN,
];
const ROLE_NAMES = {
  [UserRole.USER]: <FormattedMessage id="common/role/user"/>,
  [UserRole.ADMIN]: <FormattedMessage id="common/role/admin"/>,
};

const renderSelectedItem = (role: UserRole): React.ReactElement => (
  <span>{ROLE_NAMES[role]}</span>
);
const RenderItem = (role: UserRole): React.ReactElement => (
  <span>{ROLE_NAMES[role]}</span>
);
