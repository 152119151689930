import './ReportFormDocument.scss';
import React, { useMemo, useRef } from 'react';
import { Project } from 'src/modules/common/types/Project';
import { OutputDocumentContext, OutputDocumentContextData } from 'src/modules/output/context/OutputDocumentContext';
import { OutputPageContext } from 'src/modules/output/context/OutputPageContext';
import { OutputDocumentPageInfo } from 'src/modules/output/types/OutputDocumentPageInfo';
import { ReportContentPage } from 'src/modules/report/types/ReportContent';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentContent } from 'src/modules/report/views/ReportDocumentContent';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
  readonly project: Project;
  readonly onChange: (pages: ReadonlyArray<ReportContentPage>) => void;
};

export const ReportFormDocument = React.memo(({
  language, document, formData, project, onChange,
}: Props): React.ReactElement => {
  const pageContents = useRef(new Map<string, OutputDocumentPageInfo>());
  const documentContext = useMemo((): OutputDocumentContextData => ({
    registerPage: (page: OutputDocumentPageInfo): void => {
      pageContents.current = pageContents.current.set(page.id, page);

      const content = getReportPages(pageContents.current);
      onChange(content);
    },
    unregisterPage: (id: string): void => {
      pageContents.current.delete(id);

      const content = getReportPages(pageContents.current);
      onChange(content);
    },
  }), [onChange]);

  return (
    <div className="bp-report-form-document">
      <OutputDocumentContext.Provider value={documentContext}>
        <ReportDocumentContent
          document={document}
          formData={formData}
          project={project}
          language={language}
        />
      </OutputDocumentContext.Provider>
    </div>
  );
});

function getReportPages(contents: ReadonlyMap<string, OutputDocumentPageInfo>): ReportContentPage[] {
  const sortedPages = [...contents.values()].sort((a, b) => a.order - b.order);
  const shownPages = sortedPages.filter((it) => it.visible);

  return sortedPages.map((page) => ({
    id: page.id,
    section: page.section,
    content: (
      <OutputPageContext.Provider
        value={{
          pageNumber: shownPages.indexOf(page) + 1,
          totalPages: shownPages.length,
        }}
      >
        {page.content}
      </OutputPageContext.Provider>
    ),
  }));
}
