import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ChevronUpIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="m6 14.495 6-6.245 6 6.245-.588.612L12 9.474l-5.412 5.633L6 14.495Z"/>
    </svg>
  </IconWrapper>
));
