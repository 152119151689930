import { createSelector } from 'reselect';
import { INTL_LOCALE_MAP } from 'src/modules/common/constants/language';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { getFoundUsers } from 'src/modules/users/selectors/getFoundUsers';
import { getUsersSortingOrder } from 'src/modules/users/selectors/getUsersSortingOrder';
import { getUsersSortingTarget } from 'src/modules/users/selectors/getUsersSortingTarget';

export const getSortedFoundUsers = createSelector(
  [getLanguage, getFoundUsers, getUsersSortingTarget, getUsersSortingOrder],
  (language, foundUsers, sortingTarget, sortingOrder) => [...foundUsers].sort((a, b) => {
    const collator = new Intl.Collator(INTL_LOCALE_MAP[language]);
    const targetA = a[sortingTarget];
    const targetB = b[sortingTarget];

    if (sortingOrder === 'asc') {
      return collator.compare(targetA, targetB);
    }
    return collator.compare(targetB, targetA);
  }),
);
