import './CustomMenu.scss';
// @ts-expect-error Carbon does have the OverflowMenu component
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu';
import clsx from 'clsx';
import React, { memo } from 'react';

type Props = {
  className?: string;
  disabled?: boolean;
  flipped?: boolean;
  renderIcon?: () => React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  iconDescription: React.ReactNode;
  tooltipPosition: 'top' | 'bottom' | 'right' | 'left';
  children: React.ReactNode;
};

export const CustomMenu = memo((
  { className,
    disabled,
    flipped,
    renderIcon,
    onClick,
    onClose,
    iconDescription,
    tooltipPosition,
    children,
    ...props }: Props,
) => (
  <OverflowMenu
    menuOptionsClass={clsx('bp-custom-menu', className)}
    className={clsx(
      'bp-custom-menu__button',
      {
        'bp-custom-menu__button--disabled': disabled,
      },
    )}
    hasIconOnly={true}
    iconDescription={iconDescription}
    align={tooltipPosition}
    flipped={flipped}
    renderIcon={renderIcon}
    onClick={onClick}
    onClose={onClose}
    {...props}
  >
    {children}
  </OverflowMenu>
));
