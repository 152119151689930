import './Tooltip.scss';
import clsx from 'clsx';
import React, { memo, PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  title?: ReactNode;
  value: ReactNode;
  disabled?: boolean;
}>;

export const Tooltip = memo(({ title, value, disabled, children }: Props) => (
  <div className="bp-tooltip">
    <div className={clsx({
      'bp-tooltip__target': true,
      'bp-tooltip__target--disabled': disabled,
    })}
    >
      {children}
    </div>

    <div className={clsx({
      'bp-tooltip__content': true,
      'bp-tooltip__content--disabled': disabled,
    })}
    >
      {title ? (<p className="bp-tooltip__title">{title}</p>) : null}

      <p className="bp-tooltip__value">{value}</p>
    </div>
  </div>
));
