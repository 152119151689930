import { Action } from 'redux';
import {
  USER_ADD,
} from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function userAddingReducer(state: UsersState, action: Action): UsersState {
  if (USER_ADD.isSuccess(action)) {
    return {
      ...state,
      userSnapshots: [...state.userSnapshots, { user: action.data, action: null }],
    };
  }

  return state;
}
