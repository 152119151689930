import { createSelector } from 'reselect';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { getUsersQuery } from 'src/modules/users/selectors/getUsersQuery';
import { User } from 'src/modules/users/types/User';

export const getFoundUsers = createSelector(
  [getUsersListData, getUsersQuery],
  (userSnapshots, query): ReadonlyArray<User> => {
    const users = userSnapshots.map((userSnapshot) => userSnapshot.user);
    if (query.length < 3) {
      return users;
    }

    const lowerCasedQuery = query.toLowerCase();

    return users.filter(
      ({ firstName, lastName, email }) => firstName.toLowerCase().includes(lowerCasedQuery) ||
      lastName.toLowerCase().includes(lowerCasedQuery) ||
      email.toLowerCase().includes(lowerCasedQuery),
    );
  },
);
