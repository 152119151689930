import './ReportDocumentPageSavings.scss';
import { EnergySource, HvacArea } from '@belimo-retrofit-portal/logic';
import { Cloud, Lightning } from '@carbon/icons-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CarbonSavingsUnit } from 'src/modules/common/components/CarbonSavingsUnit';
import { EnergyEfficiencyLabelWrapper } from 'src/modules/common/components/EnergyEfficiencyLabelWrapper';
import { EnergyEfficiencyStack } from 'src/modules/common/components/EnergyEfficiencyStack';
import { EnergySavingsUnit } from 'src/modules/common/components/EnergySavingsUnit';
import { SavingsValue } from 'src/modules/common/components/SavingsValue';
import { SavingTileCard } from 'src/modules/common/components/SavingTileCard';
import { sub } from 'src/modules/common/constants/formattingHelpers';
import { ArrowRightIcon } from 'src/modules/common/icons/ArrowRightIcon';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { FanIcon } from 'src/modules/common/icons/FanIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { OutputTitle } from 'src/modules/output/views/OutputTitle';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageSavings = ({ language, document, formData }: Props): React.ReactElement => (
  <OutputDocumentPage
    id="report-savings"
    order={2_000}
    section="savings"
    visible={formData.savings.enabled}
  >
    <OutputPage language={language} showPageNumber={true}>
      <div className="bp-report-document-page-savings">
        <div className="bp-report-document-page-savings__header">
          <OutputTitle>
            <FormattedMessage id="report/document/savings/efficiency/header"/>
          </OutputTitle>
        </div>

        {(formData.savings.showOverallEfficiency || formData.savings.showPerAreaEfficiency) && (
          <div className="bp-report-document-page-savings__content">
            {formData.savings.showPotentialSavings && (
              <div className="bp-report-document-page-savings__savings">
                <div className="bp-report-document-page-savings__header">
                  <FormattedMessage id="report/document/savings/potential/header"/>
                </div>

                <div className="bp-report-document-page-savings__potential-savings">
                  <SavingTileCard
                    savingTitle={<FormattedMessage id="report/document/savings/potential/energyRelative/title"/>}
                    icon={<HeatingIcon/>}
                    amountTitle={<FormattedMessage id="report/document/savings/potential/energyRelative/total"/>}
                    amount={(
                      <SavingsValue
                        value={document.savings.annualSavings.energyRelative.total?.mul(100) ?? null}
                        precision={0}
                      />
                    )}
                    unit={<FormattedMessage id="unit/percent"/>}
                    small={true}
                  >
                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/heating"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyRelative.heating?.mul(100) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <FormattedMessage id="unit/percent"/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/cooling"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyRelative.cooling?.mul(100) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <FormattedMessage id="unit/percent"/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/electricalAuxiliary"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyRelative.electricity?.mul(100) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <FormattedMessage id="unit/percent"/>
                      </div>
                    </div>
                  </SavingTileCard>

                  <SavingTileCard
                    savingTitle={(
                      <FormattedMessage
                        id="report/document/savings/potential/carbonEmission/title"
                        values={{ sub }}
                      />
                    )}
                    icon={<Cloud size={18}/>}
                    amountTitle={(
                      <FormattedMessage
                        id="report/document/savings/potential/carbonEmission/total"
                        values={{ sub }}
                      />
                    )}
                    amount={(
                      <SavingsValue
                        value={document.savings.annualSavings.carbonEmission.total?.div(1_000_000) ?? null}
                        precision={0}
                      />
                    )}
                    unit={<CarbonSavingsUnit/>}
                    small={true}
                  >
                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/heating"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.carbonEmission.heating?.div(1_000_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <CarbonSavingsUnit/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/cooling"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.carbonEmission.cooling?.div(1_000_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <CarbonSavingsUnit/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/electricalAuxiliary"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.carbonEmission.electricity?.div(1_000_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <CarbonSavingsUnit/>
                      </div>
                    </div>
                  </SavingTileCard>

                  <SavingTileCard
                    savingTitle={
                      <FormattedMessage id="report/document/savings/potential/energyAbsolute/title"/>
                    }
                    icon={
                      <Lightning size={18}/>
                    }
                    amountTitle={<FormattedMessage id="report/document/savings/potential/energyAbsolute/total"/>}
                    amount={(
                      <SavingsValue
                        value={document.savings.annualSavings.energyAbsolute.total?.div(1_000) ?? null}
                        precision={0}
                      />
                    )}
                    unit={<EnergySavingsUnit source={EnergySource.ELECTRICITY}/>}
                    small={true}
                  >
                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/heating"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyAbsolute.heating?.div(1_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <EnergySavingsUnit source={EnergySource.ELECTRICITY}/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/cooling"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyAbsolute.cooling?.div(1_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <EnergySavingsUnit source={EnergySource.ELECTRICITY}/>
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__potential-saving-area">
                      <div className="bp-report-document-page-savings__potential-saving-name">
                        <FormattedMessage id="report/document/savings/potential/details/electricalAuxiliary"/>
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-value">
                        <SavingsValue
                          value={document.savings.annualSavings.energyAbsolute.electricity?.div(1_000) ?? null}
                          precision={0}
                        />
                      </div>

                      <div className="bp-report-document-page-savings__potential-saving-unit">
                        <EnergySavingsUnit source={EnergySource.ELECTRICITY}/>
                      </div>
                    </div>
                  </SavingTileCard>
                </div>
              </div>
            )}

            <div className="bp-report-document-page-savings__efficiency">
              {formData.savings.showOverallEfficiency && (
                <div className="bp-report-document-page-savings__efficiency-column">
                  <div className="bp-report-document-page-savings__header">
                    <FormattedMessage id="report/document/savings/efficiency/overall"/>
                  </div>

                  <div
                    className="bp-report-document-page-savings__efficiency-stacks"
                    style={{
                      paddingLeft: document.savings.ratingOverall.actual === null ? 0 : '60pt',
                    }}
                  >
                    <div className="bp-report-document-page-savings__efficiency-stack">
                      <FormattedMessage id="report/document/savings/efficiency/actual"/>

                      <EnergyEfficiencyStack
                        line={true}
                        value={document.savings.ratingOverall.actual}
                      />
                    </div>

                    <div className="bp-report-document-page-savings__efficiency-stack">
                      <FormattedMessage id="report/document/savings/efficiency/future"/>

                      <EnergyEfficiencyStack
                        line={true}
                        value={document.savings.ratingOverall.future}
                      />
                    </div>
                  </div>
                </div>
              )}

              {formData.savings.showPerAreaEfficiency && (
                <div className="bp-report-document-page-savings__efficiency-column">
                  <div className="bp-report-document-page-savings__header">
                    <FormattedMessage id="report/document/savings/efficiency/perArea"/>
                  </div>

                  <div className="bp-report-document-page-savings__overall-efficiency-periods">
                    <div><FormattedMessage id="report/document/savings/efficiency/actual"/></div>
                    <div><FormattedMessage id="report/document/savings/efficiency/future"/></div>
                  </div>

                  <div className="bp-report-document-page-savings__overall-efficiency-rows">
                    <div className="bp-report-document-page-savings__overall-efficiency-row">
                      <div className="bp-report-document-page-savings__overall-efficiency-description">
                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <HeatingIcon/>
                        </div>

                        <FormattedMessage id="report/document/energy/area/heating"/>
                      </div>

                      <div className="bp-report-document-page-savings__overall-efficiency-result">
                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.HEATING].actual}
                          sized={false}
                          disabled={false}
                        />

                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <ArrowRightIcon/>
                        </div>

                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.HEATING].future}
                          sized={false}
                          disabled={false}
                        />
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__overall-efficiency-row">
                      <div className="bp-report-document-page-savings__overall-efficiency-description">
                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <CoolingIcon/>
                        </div>

                        <FormattedMessage id="report/document/energy/area/cooling"/>
                      </div>

                      <div className="bp-report-document-page-savings__overall-efficiency-result">
                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.COOLING].actual}
                          sized={false}
                          disabled={false}
                        />

                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <ArrowRightIcon/>
                        </div>

                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.COOLING].future}
                          sized={false}
                          disabled={false}
                        />
                      </div>
                    </div>

                    <div className="bp-report-document-page-savings__overall-efficiency-row">
                      <div className="bp-report-document-page-savings__overall-efficiency-description">
                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <FanIcon/>
                        </div>

                        <FormattedMessage id="report/document/energy/area/ventilation"/>
                      </div>

                      <div className="bp-report-document-page-savings__overall-efficiency-result">
                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.VENTILATION].actual}
                          sized={false}
                          disabled={false}
                        />

                        <div className="bp-report-document-page-savings__overall-efficiency-icon">
                          <ArrowRightIcon/>
                        </div>

                        <EnergyEfficiencyLabelWrapper
                          value={document.savings.ratingPerArea[HvacArea.VENTILATION].future}
                          sized={false}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {formData.savings.showPotentialSavings && (
                <div>
                  <div className="bp-report-document-page-savings__header">
                    <FormattedMessage id="report/document/savings/about/header"/>
                  </div>

                  <div className="bp-report-document-page-savings__about">
                    <FormattedMessage
                      id="report/document/savings/about/text"
                      values={{ a, b }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </OutputPage>
  </OutputDocumentPage>
);

const a = (children: React.ReactNode): React.ReactElement => <span>{children}</span>;
const b = (children: React.ReactNode): React.ReactElement => <b>{children}</b>;
