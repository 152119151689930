import './UserListHeader.scss';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'src/migration/react-intl';
import { Checkbox } from 'src/modules/common/components/Checkbox';
import { ArrowDownIcon } from 'src/modules/common/icons/ArrowDownIcon';
import { ArrowUpIcon } from 'src/modules/common/icons/ArrowUpIcon';
import { SortingOrder, SortingTarget } from 'src/modules/users/types/Sorting';

type Props = {
  areAllSelected: boolean;
  areSomeSelected: boolean;
  sortingTarget: SortingTarget;
  setSortingTarget: (target: SortingTarget) => void;
  sortingOrder: SortingOrder;
  setSortingOrder: (order: SortingOrder) => void;
  handleHeaderCheckboxChange: () => void;
};

export const UserListHeader = memo(({
  areAllSelected,
  areSomeSelected,
  sortingTarget,
  sortingOrder,
  setSortingTarget,
  setSortingOrder,
  handleHeaderCheckboxChange,
}: Props) => {
  const intl = useIntl();

  const onSortingChange = (target: SortingTarget): void => {
    if (sortingTarget !== target) {
      setSortingTarget(target);
      return setSortingOrder('asc');
    }

    if (sortingOrder === 'asc') {
      return setSortingOrder('desc');
    }

    setSortingTarget('firstName');
    return setSortingOrder('asc');
  };

  return (
    <div className="bp-user-list-header">
      <div className="bp-user-list-header__icon"/>

      <Checkbox
        className="bp-user-list-header__checkbox"
        checked={areAllSelected}
        partial={areSomeSelected}
        onChange={handleHeaderCheckboxChange}
      />

      <button
        type="button"
        className="bp-user-list-header__text"
        onClick={() => onSortingChange('firstName')}
        aria-label={intl.formatMessage({ id: 'admin/users/list/content/header/firstName' })}
      >
        <FormattedMessage id="admin/user/form/field/firstName"/>

        {sortingTarget === 'firstName' && (
          <div className="bp-user-list-header__icon">
            {sortingOrder === 'asc'
              ? (<ArrowDownIcon/>)
              : (<ArrowUpIcon/>)}
          </div>
        )}
      </button>

      <button
        type="button"
        className="bp-user-list-header__text"
        onClick={() => onSortingChange('lastName')}
        aria-label={intl.formatMessage({ id: 'admin/users/list/content/header/lastName' })}
      >
        <FormattedMessage id="admin/user/form/field/lastName"/>

        {sortingTarget === 'lastName' && (
          <div className="bp-user-list-header__icon">
            {sortingOrder === 'asc'
              ? (<ArrowDownIcon/>)
              : (<ArrowUpIcon/>)}
          </div>
        )}
      </button>

      <button
        type="button"
        className="bp-user-list-header__text"
        onClick={() => onSortingChange('email')}
        aria-label={intl.formatMessage({ id: 'admin/users/list/content/header/username' })}
      >
        <FormattedMessage id="admin/user/form/field/username"/>

        {sortingTarget === 'email' && (
          <div className="bp-user-list-header__icon">
            {sortingOrder === 'asc'
              ? (<ArrowDownIcon/>)
              : (<ArrowUpIcon/>)}
          </div>
        )}
      </button>

      <button
        type="button"
        className="bp-user-list-header__text"
        onClick={() => onSortingChange('role')}
        aria-label={intl.formatMessage({ id: 'admin/users/list/content/header/role' })}
      >
        <FormattedMessage id="admin/user/form/field/role"/>

        {sortingTarget === 'role' && (
          <div className="bp-user-list-header__icon">
            {sortingOrder === 'asc'
              ? (<ArrowDownIcon/>)
              : (<ArrowUpIcon/>)}
          </div>
        )}
      </button>
    </div>
  );
});
