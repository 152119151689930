import { SagaIterator } from 'redux-saga';
import { USER_SYNC_PERFORM } from 'src/modules/users/actions/UserActions';
import { waitUserSynchronization } from 'src/modules/users/sagas/utils/waitUserSynchronization';
import { UserSnapshot } from 'src/modules/users/types/User';
import { call, put } from 'typed-redux-saga';

export function* syncUserForeground(): SagaIterator<ReadonlyArray<UserSnapshot>> {
  yield* put(USER_SYNC_PERFORM.request('foreground'));
  return yield* call(waitUserSynchronization);
}
