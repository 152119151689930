import { SagaIterator } from 'redux-saga';
import { USER_SYNC_PERFORM } from 'src/modules/users/actions/UserActions';
import { getUserEditing } from 'src/modules/users/selectors/getUserEditing';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { UserSnapshot } from 'src/modules/users/types/User';
import { call, select, take } from 'typed-redux-saga';

export function* waitUserSynchronization(): SagaIterator<ReadonlyArray<UserSnapshot>> {
  const userSnapshots = yield* select(getUsersListData);
  const userEditing = yield* select(getUserEditing);

  if (userEditing === null) {
    return userSnapshots;
  }
  if (userEditing.status.state === 'success') {
    return userSnapshots;
  }
  if (userEditing.status.state === 'failure') {
    throw userEditing.status.error;
  }

  yield* take([USER_SYNC_PERFORM.isSuccess, USER_SYNC_PERFORM.isFailure]);
  return yield* call(waitUserSynchronization);
}
