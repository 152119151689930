import { SagaIterator } from 'redux-saga';
import { USERS_LIST_FETCH } from 'src/modules/users/actions/UsersActions';
import { loadUsersList } from 'src/modules/users/sagas/utils/loadUsersList';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchUsersList(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching users list...');

    const data = yield* call(loadUsersList);

    yield* put(USERS_LIST_FETCH.trigger(data));
    yield* call(logDebug, 'Fetching users list... done', data);
  } catch (error) {
    yield* call(logError, 'Fetching users list... error', error);
    throw error;
  }
}
