import { NativeError, RuntimeError, Uuid } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { ANNOUNCEMENT } from 'src/modules/common/codecs/Announcement';
import { Announcement } from 'src/modules/common/types/Announcement';
import { call } from 'typed-redux-saga';

export function* readAnnouncement(announcementId: Uuid): SagaIterator<Announcement> {
  try {
    const { content } = yield* call(
      makeRestRequest,
      'PUT',
      `/notifications/announcements/${encodeURIComponent(announcementId)}`,
    );

    return yield* call(decodeOrThrow, ANNOUNCEMENT, content);
  } catch (error) {
    throw new RuntimeError('Could not mark announcement as read', {}, NativeError.wrap(error));
  }
}
