import { Action } from 'redux';
import {
  USERS_LIST_FETCH,
  USERS_LIST_ORDER,
  USERS_LIST_QUERY,
  USERS_LIST_RESET,
  USERS_LIST_TARGET,
} from 'src/modules/users/actions/UsersActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function usersListDataReducer(state: UsersState, action: Action): UsersState {
  if (USERS_LIST_FETCH.is(action)) {
    return {
      ...state,
      userSnapshots: action.data.map((user) => ({ user: user, action: null })),
    };
  }
  if (USERS_LIST_RESET.is(action)) {
    return {
      ...state,
      query: '',
      sortingOrder: 'asc',
      sortingTarget: 'firstName',
      userSnapshots: [],
    };
  }
  if (USERS_LIST_QUERY.is(action)) {
    return {
      ...state,
      query: action.data,
    };
  }
  if (USERS_LIST_TARGET.is(action)) {
    return {
      ...state,
      sortingTarget: action.data,
    };
  }
  if (USERS_LIST_ORDER.is(action)) {
    return {
      ...state,
      sortingOrder: action.data,
    };
  }
  return state;
}
