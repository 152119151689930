import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParametricSelector } from 'src/hooks/useParametricSelector';
import { DocumentLayout } from 'src/modules/common/layouts/DocumentLayout';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { OutputDocument } from 'src/modules/output/views/OutputDocument';
import { getProjectDetails } from 'src/modules/project-view/selectors/getProjectDetails';
import { getReportFormData } from 'src/modules/report/selectors/getReportFormData';
import { getReportGenerated } from 'src/modules/report/selectors/getReportGenerated';
import { ReportDocumentContent } from 'src/modules/report/views/ReportDocumentContent';

export const ReportDocumentPage = (): React.ReactElement => {
  const intl = useIntl();
  const project = useSelector(getProjectDetails);
  const language = useSelector(getLanguage);
  const document = useParametricSelector(getReportGenerated, project);
  const formData = useParametricSelector(getReportFormData, project);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'report/pageTitle' })}
        </title>
      </Helmet>

      <DocumentLayout>
        <OutputDocument>
          <ReportDocumentContent
            document={document}
            formData={formData}
            project={project}
            language={language}
          />
        </OutputDocument>
      </DocumentLayout>
    </>
  );
};
