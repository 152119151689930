import { modalProjectErrorReducer } from 'src/modules/modals/reducers/modalProjectErrorReducer';
import { modalUserErrorReducer } from 'src/modules/modals/reducers/modalUserErrorReducer';
import { ModalStackState } from 'src/modules/modals/types/ModalStackState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: ModalStackState = [];

export const modalStackReducer = chainReducers(defaultState, [
  modalProjectErrorReducer,
  modalUserErrorReducer,
]);
