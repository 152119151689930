import { Decimal, Numeric } from 'decimal.js-light';
import type { Predicate } from 'fp-ts/Predicate';
import type { Refinement } from 'fp-ts/Refinement';
import { FormError } from 'src/modules/form/types/FormError';
import { FormRule } from 'src/modules/form/types/FormRule';

export function required<T>(): FormRule<T | null | undefined> {
  return (value, context) => {
    if (value === null || value === undefined) {
      return [{
        path: context.path.join('.'),
        code: 'required',
        value: value,

        context: {},
        message: 'Value should not be empty',
      }];
    }

    return NO_ERRORS;
  };
}

export function nonEmpty(message?: string): FormRule<string> {
  return (value, context) => {
    if (value === '') {
      return [{
        path: context.path.join('.'),
        code: 'required',
        value: value,

        context: {},
        message: message || 'Value should not be empty',
      }];
    }

    return NO_ERRORS;
  };
}

export function minLength(n: number, message?: string): FormRule<string> {
  return (value, context) => {
    if (value.length >= n) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'minLength',
      value: value,

      context: { min: n },
      message: message || `Value should not be shorter than ${n} character(s)`,
    }];
  };
}

export function maxLength(n: number, message?: string): FormRule<string> {
  return (value, context) => {
    if (value.length <= n) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'maxLength',
      value: value,

      context: { max: n },
      message: message || `Value should not be longer than ${n} character(s)`,
    }];
  };
}

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function email(): FormRule<string> {
  return (value, context) => {
    if (EMAIL_REGEXP.test(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'email',
      value: value,

      context: {},
      message: 'Value is not a valid email address',
    }];
  };
}

export function literal<T>(literalArray: ReadonlyArray<T>): FormRule<T> {
  return (value, context) => {
    if (literalArray.includes(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'literal',
      value: value,

      context: {},
      message: 'Value is not a included in the list of literals',
    }];
  };
}

export function emailList(): FormRule<string> {
  return (value, context) => {
    const emailsArray = value.split(',')
      .filter((str) => str.trim().length > 0)
      .map((str) => str.trim());

    const uniqEmails = removeNonUniqueStrings(emailsArray);

    const isValidEmailList = validateEmails(uniqEmails);

    if (isValidEmailList) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'emailListInvalid',
      value: value,

      context: {},
      message: 'User(s) email address is not valid',
    }];
  };
}

export function emailAlreadyExist(existingEmailsArray: string[]): FormRule<string> {
  return (value, context) => {
    const newEmailsArray = value.split(',')
      .filter((str) => str.trim().length > 0)
      .map((str) => str.trim());

    const uniqNewEmails = removeNonUniqueStrings(newEmailsArray);

    const areSomeEmailsAlreadyAdded = uniqNewEmails.some(
      (newEmail) => existingEmailsArray.includes(newEmail),
    );

    if (areSomeEmailsAlreadyAdded) {
      return [{
        path: context.path.join('.'),
        code: 'emailAlreadyExists',
        value: value,

        context: {},
        message: 'User(s) email is already added',
      }];
    }

    return NO_ERRORS;
  };
}

function validateEmails(uniqEmails: string[]): boolean {
  return uniqEmails.every((emailStr) => EMAIL_REGEXP.test(emailStr));
}

function removeNonUniqueStrings(strings: string[]): string[] {
  return [...new Set(strings.map((string) => string.toLocaleLowerCase()))];
}

export function date(): FormRule<Date> {
  return (value, context) => {
    if (!Number.isNaN(value.getDate())) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'date',
      value: value,

      context: {},
      message: 'Value is not a valid date',
    }];
  };
}

export function gt(n: Numeric): FormRule<Decimal> {
  return (value, context) => {
    if (value.gt(n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'gt',
      value: value,

      context: { min: new Decimal(n).toNumber() },
      message: `Value should be greater than ${String(n)}`,
    }];
  };
}

export function gte(n: Numeric): FormRule<Decimal> {
  return (value, context) => {
    if (value.gte(n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'gte',
      value: value,

      context: { min: new Decimal(n).toNumber() },
      message: `Value should not be less than ${String(n)}`,
    }];
  };
}

export function lt(n: Numeric): FormRule<Decimal> {
  return (value, context) => {
    if (value.lt(n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'lt',
      value: value,

      context: { max: new Decimal(n).toNumber() },
      message: `Value should be less than ${String(n)}`,
    }];
  };
}

export function lte(n: Numeric): FormRule<Decimal> {
  return (value, context) => {
    if (value.lte(n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'lte',
      value: value,

      context: { max: new Decimal(n).toNumber() },
      message: `Value should not be greater than ${String(n)}`,
    }];
  };
}

export function allOf<T>(rules: ReadonlyArray<FormRule<T>>): FormRule<T> {
  // FIXME: should we use `reduceRight` to validate from left to right in array (e.g. `allOf([nonEmpty(), email()])`)
  //        i.g. it's first validation will be `nonEmpty()` and than `email()`
  return (value, context) => rules.reduce((errors, rule) => {
    const more = rule(value, context);

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function anyOf<T>(rules: ReadonlyArray<FormRule<T>>): FormRule<T> {
  return (value, context) => {
    for (const rule of rules) {
      const more = rule(value, context);
      if (more.length === 0) {
        return NO_ERRORS;
      }
    }

    return [{
      path: context.path.join('.'),
      code: 'invalid',
      value: value,

      context: {},
      message: 'Value does not match any rule',
    }];
  };
}

export function struct<T>(rules: { readonly [K in keyof T]: FormRule<T[K]> }): FormRule<T> {
  return (value, context) => {
    let errors = NO_ERRORS;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in rules) {
      // eslint-disable-next-line no-prototype-builtins
      if (!rules.hasOwnProperty(key)) {
        continue;
      }

      const path = context.path.concat(key);
      const rule = rules[key];
      const more = rule(value[key], { path });

      if (more.length > 0) {
        errors = errors.concat(more);
      }
    }

    return errors;
  };
}

export function nullable<T>(rule: FormRule<T>): FormRule<T | null> {
  return (value, context) => (
    value !== null
      ? rule(value, context)
      : NO_ERRORS
  );
}

export function array<T>(rule: FormRule<T>): FormRule<ReadonlyArray<T>> {
  return (value, context) => value.reduce((errors, val, key) => {
    const path = context.path.concat(key.toString());
    const more = rule(val, { path });

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function record<T>(rule: FormRule<T>): FormRule<Readonly<Record<string, T>>> {
  return (value, context) => Object.entries(value).reduce((errors, [key, val]) => {
    const path = context.path.concat(key);
    const more = rule(val, { path });

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function maybe<T, U extends T>(test: Refinement<T, U>, rule: FormRule<U>): FormRule<T>;
export function maybe<T>(test: Predicate<T>, rule: FormRule<T>): FormRule<T> {
  return (value, context) => (test(value)
    ? rule(value, context)
    : NO_ERRORS);
}

export function map<T, U = T>(fn: (value: T) => U, rule: FormRule<U>): FormRule<T> {
  return (value, context) => rule(fn(value), context);
}

export function pass<T>(): FormRule<T> {
  return () => NO_ERRORS;
}

export function match(regex: RegExp, message?: string): FormRule<string> {
  return (value, context) => {
    if (regex.test(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'match',
      value: value,

      context: { regExp: String(regex) },
      message: message || `Value should match these regular expression: "${regex}"`,
    }];
  };
}

export function matchField<T>(
  field: Extract<keyof T, string>,
  reference: Extract<keyof T, string>,
  message?: string,
): FormRule<T> {
  return (value, context) => {
    if (Object.is(value[field], value[reference])) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.concat(field).join('.'),
      code: 'match',
      value: value,

      context: {},
      message: message ?? `Value should match "${reference}" field`,
    }];
  };
}

export const NO_ERRORS: ReadonlyArray<FormError> = [];
