import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { formSubmitFailure } from 'src/modules/form/sagas/utils/formSubmitFailure';
import { formSubmitStart } from 'src/modules/form/sagas/utils/formSubmitStart';
import { formSubmitSuccess } from 'src/modules/form/sagas/utils/formSubmitSuccess';
import { formValidate } from 'src/modules/form/sagas/utils/formValidate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { USER_ADD } from 'src/modules/users/actions/UserActions';
import { USER_ADD_FORM_SCHEMA } from 'src/modules/users/constants/userAddFormSchema';
import { createUser } from 'src/modules/users/sagas/utils/createUser';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* userCreateSaga(action: GetRequestActionType<typeof USER_ADD>): SagaIterator<void> {
  const userForm = action.data;
  const userValues = userForm.values.get(userForm.currentState);

  const userSnapshots = yield* select(getUsersListData);
  const emails = userSnapshots.map((userSnapshot) => userSnapshot.user.email);
  const schema = USER_ADD_FORM_SCHEMA(emails);

  const formValid = yield* call(formValidate, userForm, schema);
  if (!formValid) {
    return;
  }

  try {
    yield* put(USER_ADD.pending(action.meta));
    yield* call(logDebug, 'Saving user...');
    yield* call(formSubmitStart, userForm);

    const savedUserData = { ...userValues, email: userValues.email.trim() };
    const savedUser = yield* call(createUser, savedUserData);

    yield* put(USER_ADD.success(savedUser));

    yield* call(
      showNotification,
      {
        variant: 'success',
        type: 'done',
        messageId: 'admin/user/notification/add/success',
      },
    );

    yield* call(logDebug, 'Saving user... done', savedUser);
    yield* call(formSubmitSuccess, userForm);
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Saving user... error', error);
    yield* put(USER_ADD.failure(NativeError.wrap(error)));

    yield* call(showNotification, { type: 'error', variant: 'error' });

    yield* call(formSubmitFailure, userForm, {
      '': {
        path: '',
        code: 'unknown',
        value: userValues,

        message: String(error),
        context: {},
      },
    });
  }
}
