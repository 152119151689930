import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import { USER_SAVE } from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function userEditingReducer(state: UsersState, action: Action): UsersState {
  if (USER_SAVE.isPending(action)) {
    return {
      ...state,
      userSnapshots: state.userSnapshots
        .filter((userSnapshot) => userSnapshot.action !== 'deleting')
        .map((userSnapshot) => (userSnapshot.user.id === action.meta.id
          ? { user: action.meta, action: 'editing' }
          : userSnapshot
        )),
      editing: {
        status: FUTURE.pending(),
      },
    };
  }
  if (USER_SAVE.isSuccess(action)) {
    return {
      ...state,
      userSnapshots: state.userSnapshots
        .filter((userSnapshot) => userSnapshot.action !== 'deleting')
        .map((userSnapshot) => (userSnapshot.user.id === action.data.id
          ? { ...userSnapshot, action: null }
          : userSnapshot
        )),
      editing: {
        status: FUTURE.success(undefined),
      },
    };
  }
  if (USER_SAVE.isFailure(action)) {
    return {
      ...state,
      editing: {
        status: FUTURE.failure(action.data),
      },
    };
  }
  return state;
}
