import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { MODAL_USER_ERROR_CLOSE, MODAL_USER_ERROR_RETRY } from 'src/modules/modals/actions/ModalActions';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { syncUserForeground } from 'src/modules/users/sagas/utils/syncUserForeground';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put } from 'typed-redux-saga';

export function* modalUserErrorSaga(
  action: GetRequestActionType<typeof MODAL_USER_ERROR_RETRY>,
): SagaIterator<void> {
  const users = action.data;

  try {
    yield* call(logDebug, 'Synchronizing user...');
    yield* put(MODAL_USER_ERROR_RETRY.pending(users));

    const synchronized = yield* call(syncUserForeground);
    yield* call(logDebug, 'Synchronizing user... done');

    yield* put(MODAL_USER_ERROR_RETRY.success(synchronized, synchronized));
    yield* put(MODAL_USER_ERROR_CLOSE.trigger());
  } catch (error) {
    const wrapped = new RuntimeError('Could not save user', { users }, NativeError.wrap(error));
    yield* call(logError, 'Synchronizing user... error', error);
    yield* put(MODAL_USER_ERROR_RETRY.failure(wrapped, users));

    yield* call(sentryCatch, wrapped);
    yield* call(showNotification, { variant: 'error', type: 'error' });
  }
}
