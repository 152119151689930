import * as React from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { ModalWrapper } from 'src/modules/modals/pages/ModalWrapper';
import { getModalStack } from 'src/modules/modals/selectors/getModalStack';
import { ProjectErrorModal } from 'src/modules/modals/views/ProjectErrorModal';
import { UserErrorModal } from 'src/modules/modals/views/UserErrorModal';

export const ModalStack = (): React.ReactElement => {
  const modals = useSelector(getModalStack);

  return (
    <TransitionGroup>
      {modals.map((modal) => {
        if (modal.type === 'project-error') {
          return (
            <ModalWrapper key={modal.type}>
              <ProjectErrorModal modal={modal}/>
            </ModalWrapper>
          );
        }

        if (modal.type === 'user-error') {
          return (
            <ModalWrapper key={modal.type}>
              <UserErrorModal modal={modal}/>
            </ModalWrapper>
          );
        }

        return null;
      })}
    </TransitionGroup>
  );
};
