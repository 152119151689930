import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const CrossIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="M6.492 18 6 17.508 11.508 12 6 6.492 6.492 6 12 11.508 17.508 6l.492.492L12.492 12 18 17.508l-.492.492L12 12.492 6.492 18Z"
      />
    </svg>
  </IconWrapper>
));
