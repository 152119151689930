import './ReportDocumentPageEvaluation.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Project } from 'src/modules/common/types/Project';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { OutputTitle } from 'src/modules/output/views/OutputTitle';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import {
  ReportDocumentPageEvaluationCharts,
} from 'src/modules/report/views/document/ReportDocumentPageEvaluationCharts';
import {
  ReportDocumentPageEvaluationOverview,
} from 'src/modules/report/views/document/ReportDocumentPageEvaluationOverview';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
  readonly project: Project;
};

export const ReportDocumentPageEvaluation = ({ language, document, formData, project }: Props): React.ReactElement => (
  <>
    <OutputDocumentPage
      id="report-evaluation-1"
      order={3_000}
      section="evaluation"
      visible={formData.evaluation.enabled}
    >
      <OutputPage language={language} showPageNumber={true}>
        <div className="bp-report-document-page-evaluation">
          <div className="bp-report-document-page-evaluation__header">
            <OutputTitle>
              <FormattedMessage id="report/document/evaluation/header"/>
            </OutputTitle>
          </div>

          <ReportDocumentPageEvaluationOverview document={document} formData={formData}/>
        </div>
      </OutputPage>
    </OutputDocumentPage>

    <OutputDocumentPage
      id="report-evaluation-2"
      order={3_001}
      section="evaluationCharts"
      visible={formData.evaluationCharts.enabled}
    >
      <OutputPage language={language} showPageNumber={true}>
        <div className="bp-report-document-page-evaluation">
          <div className="bp-report-document-page-evaluation__header">
            <OutputTitle>
              <FormattedMessage id="report/document/evaluation/header"/>
            </OutputTitle>
          </div>

          <ReportDocumentPageEvaluationCharts document={document} formData={formData} project={project}/>
        </div>
      </OutputPage>
    </OutputDocumentPage>
  </>
);
