import { NativeError } from '@belimo-retrofit-portal/logic';
import { SagaIterator } from 'redux-saga';
import { sentryCatch } from 'src/modules/config/utils/sentryCatch';
import { validate } from 'src/modules/form/utils/validate';
import { showNotification } from 'src/modules/notifications/sagas/showNotification';
import { USER_SAVE } from 'src/modules/users/actions/UserActions';
import { USER_EDIT_FORM_SCHEMA } from 'src/modules/users/constants/userEditFormSchema';
import { syncUserBackground } from 'src/modules/users/sagas/utils/syncUserBackground';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { call, put, select } from 'typed-redux-saga';

export function* userSaveSaga(action: GetRequestActionType<typeof USER_SAVE>): SagaIterator<void> {
  try {
    yield* put(USER_SAVE.pending(action.meta));
    yield* call(logDebug, 'Saving users...');

    const userSnapshots = yield* select(getUsersListData);

    for (const userSnapshot of userSnapshots) {
      const savedUserData = {
        ...userSnapshot.user,
        email: userSnapshot.user.email.trim(),
      };

      const errors = validate(savedUserData, USER_EDIT_FORM_SCHEMA);

      if (Object.keys(errors).length) {
        throw new Error('Saving user validation failed');
      }
    }

    const synchronizedUsers = yield* call(syncUserBackground);

    yield* call(logDebug, 'Saving users... done', synchronizedUsers);
    yield* put(USER_SAVE.success(action.data, action.data));
  } catch (error) {
    yield* call(sentryCatch, error);

    yield* call(logError, 'Saving users... error', error);
    yield* put(USER_SAVE.failure(NativeError.wrap(error), action.meta));

    yield* call(showNotification, { type: 'error', variant: 'error' });
  }
}
