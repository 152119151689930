import { SagaIterator } from 'redux-saga';
import { MODAL_USER_ERROR_CLOSE, MODAL_USER_ERROR_OPEN } from 'src/modules/modals/actions/ModalActions';
import { USER_SYNC_RESET } from 'src/modules/users/actions/UserActions';
import { forceUserSynchronization } from 'src/modules/users/sagas/utils/forceUserSynchronization';
import { getUsersListData } from 'src/modules/users/selectors/getUsersListData';
import { call, put, select, take } from 'typed-redux-saga';

export function* syncUser(): SagaIterator<void> {
  const userSnapshots = yield* select(getUsersListData);
  const userSnapshotsToSync = userSnapshots.filter((userSnapshot) => userSnapshot.action !== null);

  try {
    yield* call(forceUserSynchronization, userSnapshotsToSync);
  } catch (error) {
    yield* put(MODAL_USER_ERROR_OPEN.trigger(userSnapshotsToSync));
    yield* take(MODAL_USER_ERROR_CLOSE.is);

    // reset current synchronization status
    yield* put(USER_SYNC_RESET.trigger());
  }
}
