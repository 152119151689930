import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { TolgeeProvider } from '@tolgee/react';
import React from 'react';
import { Provider } from 'react-redux';
import { SagaIterator } from 'redux-saga';
import { call as callEffect, delay as delayEffect } from 'redux-saga/effects';
import { Project } from 'src/modules/common/types/Project';
import { getLanguage } from 'src/modules/config/selectors/getLanguage';
import { getTolgeeInstance } from 'src/modules/config/selectors/getTolgeeInstance';
import { OutputDocument } from 'src/modules/output/views/OutputDocument';
import { waitReportDocument } from 'src/modules/report/sagas/utils/waitReportDocument';
import { getReportFakeStore } from 'src/modules/report/selectors/getReportFakeStore';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { createShadowHost } from 'src/modules/report/utils/createShadowHost';
import { renderComponent } from 'src/modules/report/utils/renderComponent';
import { ReportDocumentContent } from 'src/modules/report/views/ReportDocumentContent';
import { htmlToPdf } from 'src/sagas/utils/htmlToPdf';
import { call, race, select } from 'typed-redux-saga';

export function* buildReportDocument(
  document: ReportGenerated,
  formData: ReportFormData,
  project: Project,
): SagaIterator<Blob> {
  const host = yield* call(createShadowHost);

  try {
    const language = yield* select(getLanguage);
    const store = yield* select(getReportFakeStore);
    const tolgee = yield* select(getTolgeeInstance);

    const content = (
      <Provider store={store}>
        <TolgeeProvider tolgee={tolgee}>
          <OutputDocument>
            <ReportDocumentContent
              document={document}
              formData={formData}
              project={project}
              language={language}
            />
          </OutputDocument>
        </TolgeeProvider>
      </Provider>
    );

    yield* call(renderComponent, host, content);
    const { timeout } = yield* race({
      timeout: delayEffect(5_000),
      complete: callEffect(waitReportDocument, host),
    });
    if (timeout) {
      throw new RuntimeError('Timeout exceeded for document readiness');
    }

    const pages = Array.from(host.element.querySelectorAll('.bp-output-page'));
    return yield* call(htmlToPdf, pages);
  } catch (error) {
    throw new RuntimeError('Could not build report PDF document', {}, NativeError.wrap(error));
  } finally {
    yield* call(host.destroy);
  }
}
