import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import { pipe } from 'fp-ts/function';
import { filter } from 'fp-ts/ReadonlyRecord';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { HttpError } from 'src/errors/HttpError';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { Translations } from 'src/modules/config/types/Translations';
import { Language } from 'src/types/common/Language';
import { isNotNull } from 'src/utils/guard';
import { call } from 'typed-redux-saga';

export function* loadTranslations(language: Language): SagaIterator<Translations> {
  try {
    const response = yield* call(fetch, `/translations/${encodeURIComponent(language)}.json`, {
      method: 'GET',
      redirect: 'error',
      credentials: 'omit',
    });
    if (!response.ok) {
      throw new HttpError(response);
    }

    const content: unknown = yield* call({
      fn: response.json,
      context: response,
    });
    const translations = yield* call(
      decodeOrThrow,
      D.record(D.nullable(D.string)),
      content,
    );
    return pipe(
      translations,
      filter(isNotNull),
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch translations', { language }, NativeError.wrap(error));
  }
}
