import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const BellIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        // eslint-disable-next-line
        d="M5.3 18.7V18h1.5V9.8c0-1.283.412-2.408 1.237-3.375A5.126 5.126 0 0 1 11.15 4.65V3.3h1.7v1.35a5.126 5.126 0 0 1 3.113 1.775c.825.967 1.237 2.092 1.237 3.375V18h1.5v.7H5.3Zm6.696 2.5c-.414 0-.767-.147-1.059-.44a1.45 1.45 0 0 1-.437-1.06h3c0 .417-.147.77-.442 1.063a1.456 1.456 0 0 1-1.063.437ZM7.5 18h9V9.8c0-1.25-.438-2.312-1.313-3.187C14.313 5.738 13.25 5.3 12 5.3c-1.25 0-2.313.438-3.188 1.313S7.5 8.55 7.5 9.8V18Z"
      />
    </svg>
  </IconWrapper>
));
