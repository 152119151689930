import { fetchAnnouncements } from 'src/modules/announcements/sagas/fetchAnnouncements';
import { emulateTransitionDelay } from 'src/modules/routing/hooks/emulateTransitionDelay';
import { authenticate } from 'src/modules/routing/hooks/resolvers/authenticate';
import { checkIsAuthenticated } from 'src/modules/routing/hooks/resolvers/checkIsAuthenticated';
import { destroySession } from 'src/modules/routing/hooks/resolvers/destroySession';
import { createStateTransitionLoggingSaga } from 'src/modules/routing/hooks/stateTransitionLogging';
import { RouteState } from 'src/modules/routing/types/RouteState';
import { composeSagas } from 'src/modules/routing/utils/composeSagas';

export const ROUTING_LAYOUT_STATES: ReadonlyArray<RouteState> = [
  {
    url: '',
    name: 'bp:global',
    parent: null,

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
  {
    url: '',
    name: 'bp:anonymous',
    parent: 'bp:global',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(0, 500),
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
      destroySession,
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
  {
    url: '',
    name: 'bp:authorized',
    parent: 'bp:global',

    onEntering: composeSagas(
      createStateTransitionLoggingSaga('Entering'),
      emulateTransitionDelay(300, 300),
      authenticate,
      fetchAnnouncements,
    ),
    onLeaving: composeSagas(
      createStateTransitionLoggingSaga('Leaving'),
    ),
    onRetaining: composeSagas(
      createStateTransitionLoggingSaga('Retaining'),
      checkIsAuthenticated,
    ),

    onEntered: composeSagas(
      createStateTransitionLoggingSaga('Entered'),
    ),
    onLeft: composeSagas(
      createStateTransitionLoggingSaga('Left'),
    ),
    onRetained: composeSagas(
      createStateTransitionLoggingSaga('Retained'),
    ),
  },
];
