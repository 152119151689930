import './AnnouncementsEmpty.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BellIcon } from 'src/modules/common/icons/BellIcon';

export const AnnouncementsEmpty = (): React.ReactNode => (
  <div className="bp-announcements-empty">
    <div className="bp-announcements-empty__icon">
      <BellIcon/>
    </div>

    <p className="bp-announcements-empty__title">
      <FormattedMessage id="common/header/announcements/center/empty/title"/>
    </p>

    <p className="bp-announcements-empty__description">
      <FormattedMessage id="common/header/announcements/center/empty/description"/>
    </p>
  </div>
);
