import { NativeError, RuntimeError } from '@belimo-retrofit-portal/logic';
import * as D from 'io-ts/Decoder';
import { SagaIterator } from 'redux-saga';
import { decodeOrThrow } from 'src/io/utils/decodeOrThrow';
import { makeRestRequest } from 'src/modules/api/sagas/utils/makeRestRequest';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { ORGANIZATION_USER } from 'src/modules/users/codecs/OrganizationUser';
import { User } from 'src/modules/users/types/User';
import { call, select } from 'typed-redux-saga';

export function* loadUsersList(): SagaIterator<ReadonlyArray<User>> {
  const authUser = yield* select(getLoggedInUser);

  try {
    const { content } = yield* call(
      makeRestRequest,
      'GET',
      `/organizations/${encodeURIComponent(authUser.organization.id)}/users`,
    );

    return yield* call(decodeOrThrow, USERS, content);
  } catch (error) {
    throw new RuntimeError('Could not load organization users', {}, NativeError.wrap(error));
  }
}

const USERS: D.Decoder<unknown, Array<User>> = D.array(ORGANIZATION_USER);
