import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const SearchIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d="m19.731 20.25-6.486-6.486a5.59 5.59 0 0 1-1.79 1.038 5.886 5.886 0 0 1-1.997.363c-1.596 0-2.946-.552-4.05-1.656-1.106-1.104-1.658-2.453-1.658-4.047 0-1.594.552-2.945 1.656-4.052C6.51 4.303 7.86 3.75 9.453 3.75c1.594 0 2.945.552 4.052 1.657 1.107 1.105 1.66 2.455 1.66 4.05 0 .71-.13 1.401-.39 2.076-.258.675-.596 1.245-1.01 1.712l6.485 6.486-.519.519ZM9.458 14.439c1.4 0 2.58-.48 3.54-1.44s1.44-2.14 1.44-3.541c0-1.401-.48-2.582-1.44-3.542s-2.14-1.44-3.54-1.44c-1.401 0-2.582.48-3.542 1.44s-1.44 2.14-1.44 3.542c0 1.4.48 2.58 1.44 3.54s2.14 1.44 3.542 1.44Z"
      />
    </svg>
  </IconWrapper>
));
