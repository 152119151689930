import { Action } from 'redux';
import {
  ANNOUNCEMENTS_FETCH,
} from 'src/modules/announcements/actions/AnnouncementsActions';
import { AnnouncementsState } from 'src/modules/announcements/types/announcementsState';

const defaultState: AnnouncementsState = {
  announcements: [],
};

export const announcementsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AnnouncementsState = defaultState,
  action: Action,
): AnnouncementsState => {
  if (ANNOUNCEMENTS_FETCH.is(action)) {
    return {
      announcements: action.data,
    };
  }

  return state;
};
