import { Action } from 'redux';
import { FUTURE } from 'src/modules/common/types/Future';
import {
  USER_SYNC_PERFORM,
  USER_SYNC_RESET,
} from 'src/modules/users/actions/UserActions';
import { UsersState } from 'src/modules/users/types/UsersState';

export function userSyncReducer(state: UsersState, action: Action): UsersState {
  const isDeletingUsers = state.userSnapshots.some((userSnapshot) => userSnapshot.action === 'deleting');

  if (USER_SYNC_PERFORM.isRequest(action)) {
    return {
      ...state,
      deleting: isDeletingUsers ? {
        status: FUTURE.pending(),
      } : state.deleting,
      editing: {
        status: FUTURE.pending(),
      },
    };
  }
  if (USER_SYNC_PERFORM.isPending(action)) {
    return {
      ...state,
      deleting: isDeletingUsers ? {
        status: FUTURE.pending(),
      } : state.deleting,
      editing: {
        status: FUTURE.pending(),
      },
    };
  }
  if (USER_SYNC_PERFORM.isSuccess(action)) {
    return {
      ...state,
      deleting: isDeletingUsers ? {
        status: FUTURE.success(undefined),
      } : state.deleting,
      editing: {
        status: FUTURE.success(undefined),
      },
    };
  }
  if (USER_SYNC_PERFORM.isFailure(action)) {
    return {
      ...state,
      deleting: isDeletingUsers ? {
        status: FUTURE.failure(action.data),
      } : state.deleting,
      editing: {
        status: FUTURE.failure(action.data),
      },
    };
  }
  if (USER_SYNC_RESET.is(action)) {
    return {
      ...state,
      deleting: null,
      editing: null,
    };
  }
  return state;
}
