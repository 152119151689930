import './ReportDocumentPageEnergy.scss';
import { HvacArea } from '@belimo-retrofit-portal/logic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CoolingIcon } from 'src/modules/common/icons/CoolingIcon';
import { FanIcon } from 'src/modules/common/icons/FanIcon';
import { HeatingIcon } from 'src/modules/common/icons/HeatingIcon';
import { OutputDocumentPage } from 'src/modules/output/views/OutputDocumentPage';
import { OutputPage } from 'src/modules/output/views/OutputPage';
import { OutputTitle } from 'src/modules/output/views/OutputTitle';
import { ReportFormData } from 'src/modules/report/types/ReportFormData';
import { ReportGenerated } from 'src/modules/report/types/ReportGenerated';
import { ReportDocumentPageEnergyThermal } from 'src/modules/report/views/document/ReportDocumentPageEnergyThermal';
import {
  ReportDocumentPageEnergyVentilation,
} from 'src/modules/report/views/document/ReportDocumentPageEnergyVentilation';
import { Language } from 'src/types/common/Language';

type Props = {
  readonly language: Language;
  readonly document: ReportGenerated;
  readonly formData: ReportFormData;
};

export const ReportDocumentPageEnergy = ({ language, document, formData }: Props): React.ReactElement => (
  <OutputDocumentPage
    id="report-energy"
    order={4_000}
    section="energy"
    visible={formData.energy.enabled}
  >
    <OutputPage language={language} showPageNumber={true}>
      <div className="bp-report-document-page-energy">
        <div className="bp-report-document-page-energy__header">
          <OutputTitle>
            <FormattedMessage id="report/document/energy/header"/>
          </OutputTitle>
        </div>

        <div className="bp-report-document-page-energy__content">
          {formData.energy.showHeating && (
            <div className="bp-report-document-page-energy__area">
              <ReportDocumentPageEnergyThermal
                icon={<HeatingIcon/>}
                name={<FormattedMessage id="report/document/energy/area/heating"/>}
                values={document.energy.areas[HvacArea.HEATING]}
                currency={document.energy.currency}
              />
            </div>
          )}

          {formData.energy.showCooling && (
            <div className="bp-report-document-page-energy__area">
              <ReportDocumentPageEnergyThermal
                icon={<CoolingIcon/>}
                name={<FormattedMessage id="report/document/energy/area/cooling"/>}
                values={document.energy.areas[HvacArea.COOLING]}
                currency={document.energy.currency}
              />
            </div>
          )}

          {formData.energy.showVentilation && (
            <div className="bp-report-document-page-energy__area">
              <ReportDocumentPageEnergyVentilation
                icon={<FanIcon/>}
                name={<FormattedMessage id="report/document/energy/area/ventilation"/>}
                values={document.energy.areas[HvacArea.VENTILATION]}
                currency={document.energy.currency}
              />
            </div>
          )}
        </div>

        {(formData.energy.showComments && document.energy.comment) && (
          <div className="bp-report-document-page-energy__comment">
            <div className="bp-report-document-page-energy__comment-title">
              <FormattedMessage id="report/document/energy/comments"/>
            </div>

            <div className="bp-report-document-page-energy__comment-text">
              {document.energy.comment}
            </div>
          </div>
        )}
      </div>
    </OutputPage>
  </OutputDocumentPage>
);
