import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Store } from 'redux';
import { BasedataPage } from 'src/modules/basedata/pages/BasedataPage';
import { ConfigProvider } from 'src/modules/config/containers/ConfigProvider';
import { ErrorBoundary } from 'src/modules/error/containers/ErrorBoundary';
import { ErrorPage } from 'src/modules/error/pages/ErrorPage';
import { EvaluationPage } from 'src/modules/evaluation/pages/EvaluationPage';
import { HvacPage } from 'src/modules/hvac/pages/HvacPage';
import { LoginPage } from 'src/modules/login/pages/LoginPage';
import { ModalStack } from 'src/modules/modals/pages/ModalStack';
import { OrganizationPage } from 'src/modules/organization/pages/OrganizationPage';
import { PasswordForgotPage } from 'src/modules/password-forgot/pages/PasswordForgotPage';
import { PasswordResetPage } from 'src/modules/password-reset/pages/PasswordResetPage';
import { PasswordSetupPage } from 'src/modules/password-reset/pages/PasswordSetupPage';
import { ProfilePage } from 'src/modules/profile/page/ProfilePage';
import { ProjectListPage } from 'src/modules/project-list/pages/ProjectListPage';
import { ReportDocumentPage } from 'src/modules/report/pages/ReportDocumentPage';
import { ReportPage } from 'src/modules/report/pages/ReportPage';
import { Initialization } from 'src/modules/routing/containers/Initialization';
import { RouteSwitch } from 'src/modules/routing/containers/RouteSwitch';
import { RouteNotFoundError } from 'src/modules/routing/errors/RouteNotFoundError';
import { SchemaPage } from 'src/modules/schema/pages/SchemaPage';
import { ScreenSizeGuard } from 'src/modules/screen-size-guard/views/ScreenSizeGuard';
import { ScrollRestoration } from 'src/modules/scroll/containers/ScrollRestoration';
import { UsersPage } from 'src/modules/users/pages/UsersPage';
import { RootState } from 'src/types/RootState';

type Props = {
  readonly store: Store<RootState>;
  readonly history: History;
};

export const Root = ({ store, history }: Props): React.ReactElement => (
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <ConfigProvider>
            <ScreenSizeGuard>
              <ConnectedRouter history={history}>
                <Initialization>
                  <>
                    <ScrollRestoration history={history}/>

                    <RouteSwitch>
                      <Route
                        path="/login"
                        exact={true}
                        component={LoginPage}
                      />
                      <Route
                        path="/password/forgot"
                        exact={true}
                        component={PasswordForgotPage}
                      />
                      <Route
                        path="/password/reset"
                        exact={true}
                        component={PasswordResetPage}
                      />
                      <Route
                        path="/password/setup"
                        exact={true}
                        component={PasswordSetupPage}
                      />
                      <Route
                        path="/admin/users"
                        exact={true}
                        component={UsersPage}
                      />
                      <Route
                        path="/admin/organization"
                        exact={true}
                        component={OrganizationPage}
                      />
                      <Route
                        path="/projects"
                        exact={true}
                        component={ProjectListPage}
                      />
                      <Route
                        path="/project/:id([-a-f0-9]+)"
                        exact={true}
                        component={BasedataPage}
                      />
                      <Route
                        path="/project/:id/hvac"
                        exact={true}
                        component={HvacPage}
                      />
                      <Route
                        path="/project/:id/schema"
                        exact={true}
                        component={SchemaPage}
                      />
                      <Route
                        path="/project/:id/evaluation"
                        exact={true}
                        component={EvaluationPage}
                      />
                      <Route
                        path="/project/:id/report"
                        exact={true}
                        component={ReportPage}
                      />
                      <Route
                        path="/project/:id/report/document"
                        exact={true}
                        component={ReportDocumentPage}
                      />
                      <Route
                        path="/profile"
                        exact={true}
                        component={ProfilePage}
                      />
                      <Route
                        path="*"
                        component={NotFound}
                      />
                    </RouteSwitch>
                    <ModalStack/>
                  </>
                </Initialization>
              </ConnectedRouter>
            </ScreenSizeGuard>
          </ConfigProvider>
        </ErrorBoundary>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

const NotFound = ({ location }: RouteComponentProps): React.ReactElement => {
  const query = Object.fromEntries(new URLSearchParams(location.search));
  const error = new RouteNotFoundError({ ...location, query });
  return <ErrorPage error={error}/>;
};
