import './Announcements.scss';
import dayjs from 'dayjs';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import {
  ANNOUNCEMENTS_READ,
} from 'src/modules/announcements/actions/AnnouncementsActions';
import { AnnouncementsEmpty } from 'src/modules/announcements/components/AnnouncementsEmpty';
import {
  getAnnouncementState,
} from 'src/modules/announcements/selectors/getAnnouncementState';
import { OverflowMenu } from 'src/modules/common/components/OverflowMenu';
import { OverflowMenuItem } from 'src/modules/common/components/OverflowMenuItem';
import { b, em, i, strong } from 'src/modules/common/constants/formattingHelpers';
import { BellIcon } from 'src/modules/common/icons/BellIcon';
import { CircleFilledIcon } from 'src/modules/common/icons/CircleFilledIcon';

export const Announcements = memo(() => {
  const intl = useIntl();

  const announcementsState = useSelector(getAnnouncementState);

  const isBadgeHidden = announcementsState.announcements.every((announcement) => announcement.read);

  const readAnnouncements = useAction(ANNOUNCEMENTS_READ.trigger);

  return (
    <OverflowMenu
      interactive={false}
      buttonTooltip={intl.formatMessage({ id: 'common/header/announcements' })}
      buttonSize="xl"
      onClose={readAnnouncements}
      label={(
        <div className="bp-announcements__icon">
          <BellIcon/>

          {!isBadgeHidden && (
            <div className="bp-announcements__badge">
              <CircleFilledIcon/>
            </div>
          )}
        </div>
      )}
    >
      <div className="bp-announcements__list">
        <div className="bp-announcements__list-header">
          <FormattedMessage id="common/header/announcements/title"/>
        </div>

        {announcementsState.announcements.length
          ? announcementsState.announcements.map((announcement) => (
            <OverflowMenuItem
              key={announcement.id}
              className="bp-announcements__item"
            >
              <div className="bp-announcements__notification">
                <div className="bp-announcements__notification-header">
                  <p className="bp-announcements__notification-title">
                    <FormattedMessage id={announcement.title} values={{ i, b, em, strong }}/>
                  </p>

                  <div className="bp-announcements__notification-details">
                    <p className="bp-announcements__notification-date">
                      {dayjs(announcement.date).format(intl.formatMessage({ id: 'common/dateFormat' }))}
                    </p>

                    {!announcement.read && (
                      <div className="bp-announcements__notification-badge">
                        <CircleFilledIcon/>
                      </div>
                    )}
                  </div>
                </div>

                <p className="bp-announcements__notification-description">
                  <FormattedMessage id={announcement.description} values={{ i, b, em, strong }}/>
                </p>
              </div>
            </OverflowMenuItem>
          )) : (
            <AnnouncementsEmpty/>
          )}
      </div>
    </OverflowMenu>
  );
});
