import { Stack } from '@carbon/react';
import { useForm, useFormWatch } from '@form-ts/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { FormSection } from 'src/modules/common/components/FormSection';
import { UserForm } from 'src/modules/common/components/UserForm';
import { UserFormData } from 'src/modules/common/types/UserFormData';
import { useFormValidator } from 'src/modules/form/hooks/useFormValidator';
import { FormError } from 'src/modules/form/types/FormError';
import { getLoggedInUser } from 'src/modules/login/selectors/getLoggedInUser';
import { PROFILE_CHANGE_PASSWORD, PROFILE_SAVE } from 'src/modules/profile/actions/ProfileActions';
import { CHANGE_PASSWORD_SCHEMA } from 'src/modules/profile/constants/schema';
import { ChangePasswordData } from 'src/modules/profile/types/ChangePasswordData';
import { PasswordChangeForm } from 'src/modules/profile/views/PasswordChangeForm';
import { ProfileHeader } from 'src/modules/profile/views/ProfileHeader';
import { USER_EDIT_FORM_SCHEMA } from 'src/modules/users/constants/userEditFormSchema';

export const ProfileContent = (): React.ReactElement => {
  const intl = useIntl();

  const authUser = useSelector(getLoggedInUser);

  const profileForm = useForm<UserFormData, FormError>('profile.user-settings', {
    initialValues: {
      email: authUser.email,
      firstName: authUser.firstName,
      lastName: authUser.lastName,
      role: authUser.role,
    },
    reinitialize: false,
  });

  const changePasswordForm = useForm<ChangePasswordData, FormError>('profile.password-change', {
    initialValues: {
      confirmation: '',
      password: '',
    },
    reinitialize: false,
  });

  useFormValidator(profileForm, USER_EDIT_FORM_SCHEMA);
  useFormValidator(changePasswordForm, CHANGE_PASSWORD_SCHEMA);

  const isProfileFormSubmitting = useFormWatch(profileForm, profileForm.submitting.get);

  const saveProfile = useBoundAction(PROFILE_SAVE.request, profileForm);
  const changePassword = useBoundAction(PROFILE_CHANGE_PASSWORD.request, changePasswordForm);

  return (
    <>
      <ProfileHeader
        onSave={saveProfile}
        isSubmitting={isProfileFormSubmitting}
        title={`${authUser.firstName} ${authUser.lastName}`}
      />

      <Stack as="main" className="bp-profile-page__body" gap="1rem" orientation="vertical">
        <FormSection title={intl.formatMessage({ id: 'profile/form/section/userSettings/title' })}>
          <UserForm
            className="bp-profile-page__form-wrapper"
            disableEmail={true}
            disableRole={true}
            form={profileForm}
            isUserCreation={false}
          />
        </FormSection>

        <FormSection title={intl.formatMessage({ id: 'profile/form/section/changePassword/title' })}>
          <PasswordChangeForm form={changePasswordForm} onChange={changePassword}/>
        </FormSection>
      </Stack>
    </>
  );
};
