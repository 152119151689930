import { SagaIterator } from 'redux-saga';
import {
  ANNOUNCEMENTS_READ,
} from 'src/modules/announcements/actions/AnnouncementsActions';
import { readAnnouncements } from 'src/modules/announcements/sagas/readAnnouncements';
import { takeLatest } from 'typed-redux-saga';

export function* announcementsSaga(): SagaIterator<void> {
  yield* takeLatest(ANNOUNCEMENTS_READ.is, readAnnouncements);
}
