import { SagaIterator } from 'redux-saga';
import {
  ANNOUNCEMENTS_FETCH,
} from 'src/modules/announcements/actions/AnnouncementsActions';
import { loadAnnouncements } from 'src/modules/announcements/sagas/utils/loadAnnouncements';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* fetchAnnouncements(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching announcements...');

    const data = yield* call(loadAnnouncements);

    yield* put(ANNOUNCEMENTS_FETCH.trigger(data));
    yield* call(logDebug, 'Fetching announcements... done', data);
  } catch (error) {
    yield* call(logError, 'Fetching announcements... error', error);
    throw error;
  }
}
