import { useEffect } from 'react';

export const useOutsideClick = <T extends (Element | null)>(ref: T, callback: () => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
      const node = event.target;

      if (!(node instanceof Node)) {
        return;
      }

      if (ref && !ref?.contains(node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, callback]);
};
