import './Dropdown.scss';
import clsx from 'clsx';
import React, { memo, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { useOutsideClick } from 'src/modules/common/hooks/useOutsideClick';
import { CheckIcon } from 'src/modules/common/icons/CheckIcon';
import { ChevronDownIcon } from 'src/modules/common/icons/ChevronDownIcon';
import { ChevronUpIcon } from 'src/modules/common/icons/ChevronUpIcon';

type Props<T> = PropsWithChildren<{
  selectedValue: T;
  renderLabel?: (arg: T) => ReactNode;
  icon?: ReactNode;
  options: readonly T[];
  size?: 'sm' | 'md' | 'lg' | 'xlg';
  outlined?: boolean;
  shaded?: boolean;
  disabled?: boolean;
  onChange: (value: T) => void;
}>;

// eslint-disable-next-line react/function-component-definition
function DropdownWithoutMemo<T>({
  onChange,
  renderLabel = (option: T): string => String(option),
  icon,
  selectedValue,
  options,
  size = 'md',
  outlined = false,
  shaded = true,
  disabled,
  children,
}: Props<T>): ReactNode {
  const [listOpened, setListOpened] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useOutsideClick(ref, () => setListOpened(false));

  const selected = options.find((option) => option === selectedValue) || null;

  const isOnlyOneOption = selected !== null && options.length === 1;

  const chevron = useMemo(() => {
    if (isOnlyOneOption) {
      return null;
    }

    return (
      listOpened
        ? <ChevronUpIcon/>
        : <ChevronDownIcon/>
    );
  }, [isOnlyOneOption, listOpened]);

  const handleSelect = (value: T): void => {
    onChange(value);
    setListOpened(false);
  };

  const handleOpen = (): void => {
    if (isOnlyOneOption || disabled) {
      return;
    }

    setListOpened((prev) => !prev);
  };

  return (
    <div className={clsx({
      'bp-dropdown': true,
      'bp-dropdown--disabled': disabled,
    })}
    >
      {children && (
        <div className={clsx({
          'bp-dropdown__label': true,
          'bp-dropdown__label--disabled': disabled,
        })}
        >
          {children}
        </div>
      )}

      <div className="bp-dropdown__container" ref={setRef}>
        <button
          type="button"
          className={clsx({
            'bp-dropdown__value': true,
            'bp-dropdown__value--outlined': outlined,
            'bp-dropdown__value--shaded': shaded,
            'bp-dropdown__value--single': isOnlyOneOption,
            'bp-dropdown__value--disabled': disabled,
            [`bp-dropdown__value--${size}`]: true,
          })}
          onClick={handleOpen}
        >
          {selected ? renderLabel(selected) : null}

          <div className="bp-dropdown__value-icon">
            {icon || chevron}
          </div>
        </button>

        <div className={clsx({
          'bp-dropdown__list': true,
          'bp-dropdown__list--opened': listOpened,
        })}
        >
          {options.map((option) => (
            <button
              key={String(option)}
              type="button"
              className={clsx({
                'bp-dropdown__option': true,
                'bp-dropdown__option--selected': selected === option,
              })}
              onClick={() => handleSelect(option)}
            >
              {renderLabel(option)}

              {selected === option && (
                <span className="bp-dropdown__option-icon">
                  <CheckIcon/>
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

const typedMemo: <T>(c: T) => T = memo;
export const Dropdown = typedMemo(DropdownWithoutMemo);
