import { userAddingReducer } from 'src/modules/users/reducers/userAddingReducer';
import { userDeletingReducer } from 'src/modules/users/reducers/userDeletingReducer';
import { userEditingReducer } from 'src/modules/users/reducers/userEditingReducer';
import { usersListDataReducer as usersDataReducer } from 'src/modules/users/reducers/usersListDataReducer';
import { userSyncReducer } from 'src/modules/users/reducers/userSyncReducer';
import { UsersState } from 'src/modules/users/types/UsersState';
import { chainReducers } from 'src/utils/chainReducers';

const defaultState: UsersState = {
  userSnapshots: [],
  deleting: null,
  editing: null,
  query: '',
  sortingTarget: 'firstName',
  sortingOrder: 'asc',
};

export const usersReducer = chainReducers(defaultState, [
  userAddingReducer,
  userDeletingReducer,
  userEditingReducer,
  usersDataReducer,
  userSyncReducer,
]);
