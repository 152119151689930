import './Checkbox.scss';
import clsx from 'clsx';
import React, { memo } from 'react';

type Props = {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  partial?: boolean;
  onChange?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const Checkbox = memo(({ label, disabled, checked, className, partial, onChange }: Props) => (
  <div
    className={clsx({
      'bp-checkbox-wrapper': true,
      'bp-checkbox-wrapper--disabled': disabled,
    }, className)}
    onClick={onChange}
    role="checkbox"
    tabIndex={0}
    aria-checked={checked}
    onKeyDown={() => null}
  >
    <div className={clsx({
      'bp-checkbox': true,
      'bp-checkbox--checked': checked,
      'bp-checkbox--partial': partial,
    })}
    />

    {label && (<p className="bp-checkbox__label">{label}</p>)}
  </div>
));
