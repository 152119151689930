import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ArrowDownIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m6.75 13.55.515-.5 4.375 4.35V5.25h.72V17.4l4.375-4.35.515.5-5.25 5.2-5.25-5.2Z"
      />
    </svg>
  </IconWrapper>
));
