import { SagaIterator } from 'redux-saga';
import { call as callEffect, take as takeEffect } from 'redux-saga/effects';
import { CancelledError } from 'src/errors/CancelledError';
import { USER_SYNC_PERFORM } from 'src/modules/users/actions/UserActions';
import { waitUserSynchronization } from 'src/modules/users/sagas/utils/waitUserSynchronization';
import { UserSnapshot } from 'src/modules/users/types/User';
import { cancel, put, race } from 'typed-redux-saga';

export function* syncUserBackground(): SagaIterator<ReadonlyArray<UserSnapshot>> {
  yield* put(USER_SYNC_PERFORM.request('background'));

  const { synchronized } = yield* race({
    synchronized: callEffect(waitUserSynchronization),
    cancelled: takeEffect(USER_SYNC_PERFORM.isRequest),
  });
  if (synchronized) {
    return synchronized;
  }

  yield* cancel();
  throw new CancelledError('Explicit abort of control flow');
}
