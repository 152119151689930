import React, { memo } from 'react';
import { IconWrapper } from 'src/modules/common/icons/IconWrapper';

export const ChevronDownIcon = memo(() => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M18 8.755 12 15 6 8.755l.588-.612L12 13.775l5.412-5.632.588.612Z"/>
    </svg>
  </IconWrapper>
));
