import { SortingOrder, SortingTarget } from 'src/modules/users/types/Sorting';
import { User } from 'src/modules/users/types/User';
import { createTrigger } from 'src/utils/createTrigger';

export const USERS_LIST_FETCH = createTrigger<ReadonlyArray<User>>('USERS_LIST_FETCH');
export const USERS_LIST_RESET = createTrigger<void>('USERS_LIST_RESET');

export const USERS_LIST_QUERY = createTrigger<string>('USER_LIST_QUERY');
export const USERS_LIST_TARGET = createTrigger<SortingTarget>('USERS_LIST_TARGET');
export const USERS_LIST_ORDER = createTrigger<SortingOrder>('USERS_LIST_ORDER');
